import React from "react";
import styled from "styled-components";
import HomeMarquee from './HomeMarquee';
import PropTypes from 'prop-types';
import {
  GlobalStyle,
} from 'stylecomponents';

const MarqueeContainer = styled.div`
  width: 100%;
  padding: ${GlobalStyle.ViewSpace}px 0;
`;
const MarqueeContent = GlobalStyle.BasicBlock.extend`
  display: flex;
  position: relative;
  width: 100%;
  height: 35px;
  line-height: 35px;
  font-size: 14px;
  background-color: #fff;
  color: #4a4a4a;
  white-space: nowrap;

  i {
    font-size: 24px;
    padding: 0px 12px 0px 0px;
    border-right: solid 1px #ddd;
    margin: 5px 0px 5px 10px;
    color: #F75C2F;
  }
`;

const MarqueeList = styled.span`
  margin-left: 5px;
  margin-right: 5px;
`;

const MarqueeRed = styled.span`
    color: #D0021B;
`;
const MarqueeGreen = styled.span`
    color: #54B62C;
`;

const Marquee = ({ lotterys, rate }) => {

  return (
    <GlobalStyle.SubContainer>
      <MarqueeContainer>
        <MarqueeContent>
          <i className="zmdi zmdi-volume-up"></i>
          <HomeMarquee rate={rate}>
            {lotterys.map(({ nickname = '', productname = '' }, idx) => {

              const _nickname = nickname.trim();
              
              if (!_nickname) {
                return null;
              }

              return (
                <MarqueeList key={idx}>
                  恭喜
                  <MarqueeRed>{_nickname.trim()}</MarqueeRed>
                  抽獎獲得《
                  <MarqueeGreen>{productname.trim()}</MarqueeGreen>
                  》
                </MarqueeList>
              );
            })}
          </HomeMarquee>
        </MarqueeContent>
      </MarqueeContainer>
    </GlobalStyle.SubContainer>
  );
};

Marquee.propTypes = {
  lotterys: PropTypes.array.isRequired,
  rate: PropTypes.number,
};

Marquee.defaultProps = {
  rate: 60,
};

export default Marquee;
