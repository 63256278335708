

function convetDBResultSetToArray(dbresultset){

    const colCount = dbresultset.colCount;
    const colNames = dbresultset.colNames;
    const resultSize = dbresultset.resultSize;
    const resultMap = dbresultset.resultMap;
    // const totalResultSize = dbresultset.totalResultSize;
    let results = [];

    for (let rowIdx = 0; rowIdx < resultSize; rowIdx++) {
        let result = {};
        for (let colIdx = 0; colIdx < colCount; colIdx++) {
            
            const colName = colNames[colIdx];
            const key = colName + rowIdx;
            const value = resultMap[key];
            result[colName] = value;
        }
        results.push(result);
    }

    return results;

}

export default convetDBResultSetToArray;