/*=====================================
    RepairView

    Author: Ac
    CreateTime: 2018 / 12 / 14
=====================================*/

/*--------------------------
    Import
--------------------------*/
import React from 'react';
import styled from 'styled-components';

/*--------------------------
    Styled
--------------------------*/
const Container = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-size: 20px;
    font-weight: bold;
    background-color: #fff;
    padding: 30px;
    border-radius: 3px;
    overflow: hidden;
`;
const MaintainImg = styled.img`
    max-width: 60%;
    width: 100%;
`;
const MaintainText = styled.p`
    margin: 0 auto;
    font-size: 14px;
    color: #09263C;
    padding: 0 30px;
    line-height: 18px;
    width: 100%;
    text-align: center;
`;
/*--------------------------
    Main Component
--------------------------*/
const RepairView = () => (
    <Container id='maintain'>
        <MaintainImg
            src='/images/maintain.svg'
            alt='maintain' />
        <MaintainText>
            客服電話：(02) 7731-2321 <br/>
            <span>(週一至週五 10:00~18:00)</span> <br/><br/>
            <span>
                週六日請使用<br/>
                <a
                    href='mailto:service@9199.com.tw'
                    rel='noopener noreferrer'
                    title='9199客服信箱'>
                    Mail至 service@9199.com.tw
                </a>
            </span>
        </MaintainText>
    </Container>
);

/*--------------------------
    Export
--------------------------*/
export default RepairView;