import GLOBAL from 'nonnglobal';
import customAxios from 'api/customAxios';

/**
 * 點擊廣告記錄
 * @param {string} advid 
 * @author Ac
 * @time 2020 / 06 / 23
 */
function adClick(advid) {
    return () => {
        const promise = new Promise((resolve, reject) => {

            const url = `/AdAction.do?op=adClick`;
            const form = new FormData();
            form.append('advid', advid);

            customAxios(url, {
                method: 'POST',
                body: form,
            }).then(response => {
                response.json().then(result => {

                    if (response.status === 503) {
                        // AWS server正常，apache也存活，但是resin已經被關閉的情況下會出現此錯誤
                        reject({error: 'error.503error', message: '伺服器沒有開啟'});
                        return;
                    }

                    if(!result || result.error) {
                        reject(result && result.error);
                        return;
                    }

                    resolve(result);
                }).catch(error => {
                    reject(error);
                });
            }).catch(error => {
                reject(error);
            });
        });

        return promise;
    };
}

/**
 * 廣告出現記錄
 * @param {string} advid 
 * @author Ac
 * @time 2020 / 06 / 23
 */
function adImpress(advid) {
    return () => {
        const promise = new Promise((resolve, reject) => {

            const url = `/AdAction.do?op=adImpress`;
            const form = new FormData();
            form.append('advid', advid);

            customAxios(url, {
                method: 'POST',
                body: form,
            }).then(response => {
                response.json().then(result => {

                    if (response.status === 503) {
                        // AWS server正常，apache也存活，但是resin已經被關閉的情況下會出現此錯誤
                        reject({error: 'error.503error', message: '伺服器沒有開啟'});
                        return;
                    }

                    if(!result || result.error) {
                        reject(result && result.error);
                        return;
                    }

                    resolve(result);
                }).catch(error => {
                    reject(error);
                });
            }).catch(error => {
                reject(error);
            });
        });

        return promise;
    };
}

export default {
    adClick,
    adImpress,
};