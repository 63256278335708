import {
    useSelector,
    useDispatch,
} from 'react-redux';
import { bindActionCreators } from 'redux';
import { createSelector } from 'reselect';

const defaultSelector = createSelector(
    state => state,
    state => state
);

const useRedux = (originSelector, actions, options = {}) => {

    const selector = typeof originSelector !== 'function'
        ? defaultSelector
        : originSelector;

    const state = useSelector(selector, options.shouldHooksUpdate);
    const dispatch = useDispatch();

    if (actions === null || Object.is(actions, undefined)) {
        return [state, dispatch];
    } 
    
    const actionsKeys = Object.keys(actions);

    const boundActions = actionsKeys.reduce((acc, actionsKey) => {
        acc[actionsKey] = bindActionCreators(actions[actionsKey], dispatch);
        return acc;
    }, {});
    
    return [state, boundActions];
};

export default useRedux;