import actionTypes from "actions/types/vitemActionTypes";
import convetDBResultSetToArray from "actions/util/convetDBResultSetToArray";
import {uniq} from 'lodash';
/*==========================
    Reducers
==========================*/
const reducers = {

    [actionTypes.getVItemTopSellerListForGameSuccess]: function(state, action) {
        return getVItemTopSellerListForGameSuccess(state, action);
    },
    [actionTypes.getVItemSellerListForGameSuccess]: function(state, action) {
        return getVItemSellerListForGameSuccess(state, action);
    },
    [actionTypes.initOrderSuccess]: function(state, action) {
        return initOrderSuccess(state, action);
    },
    [actionTypes.buildVItemCartOrderSuccess]: function(state, action) {
        return buildVItemCartOrderSuccess(state, action);
    },
    [actionTypes.payVItemCartSuccess]: function(state, action) {
        return payVItemCartSuccess(state, action);
    },
    [actionTypes.listTopPurchasesSuccess]: function(state, action) {
        return listTopPurchasesSuccess(state, action);
    },
    [actionTypes.listPurchasesSuccess]: function(state, action) {
        return listPurchasesSuccess(state, action);
    },
    [actionTypes.initPurchaseOrderSuccess]: function(state, action) {
        return initPurchaseOrderSuccess(state, action);
    },
    [actionTypes.addPurchaseOrderSuccess]: function(state, action) {
        return addPurchaseOrderSuccess(state, action);
    },
    [actionTypes.addPurchaseFormData]: function(state, action) {
        return addPurchaseFormData(state, action);
    },
};



/*==========================
    Method
==========================*/

function initPurchaseOrderSuccess (state, { payload }) {

    const { result } = payload;

    const {
        list,
        grades,
        hadBoughtVitem = {},
    } = result;

    const dbresult = convetDBResultSetToArray(list);
    if(dbresult.length === 0)
        return state;
    
    const { resultSize = 0 } = hadBoughtVitem;

    const grade = convetDBResultSetToArray(grades);

    const itemMap = dbresult.reduce((map, item, idx)=>{
        const sellerid = item.viprovid + ',' + item.gameid + ',' +item.gameserverid;
        const orgmap = state.purchaseMap[sellerid] || {};
        map[sellerid] = Object.assign({}, orgmap, item, {
            sellerid,
            ...grade[idx],
            hadBoughtVitem: resultSize > 0 ? true : false,
        });
        return map;
    }, {});
    
    const newItemMap = Object.assign({}, state.purchaseMap, itemMap);
    return Object.assign({}, state, {
        purchaseMap: newItemMap,
        isloading: false,
    });
}

function addPurchaseOrderSuccess (state, action) {
    
    return Object.assign({}, state, {
        isloading: false,
	});
}

// purchaseMap: {},
// purchaseListIdsByGameAndServerId: {},

function listTopPurchasesSuccess (state, action) {
    const dbresult = convetDBResultSetToArray(action.payload.result.list);
    if(dbresult.length === 0) {
        return Object.assign({}, state, {
            isloading: false,
        });
    }

    const grade = convetDBResultSetToArray(action.payload.result.grades);
    const { gameid, gameserverid } = action.payload;
    
    const itemMap = dbresult.reduce((map, item, idx)=>{
        const sellerid = item.viprovid + ',' + item.gameid + ',' +item.gameserverid;
        const orgmap = state.purchaseMap[sellerid] || {};
        map[sellerid] = Object.assign({}, orgmap, item, {sellerid, ...grade[idx]});
        return map;
    }, {});
    
    const newItemMap = Object.assign({}, state.purchaseMap, itemMap);

    const ids = dbresult.map((item, idx)=>{
        const sellerid = item.viprovid + ',' + item.gameid + ',' +item.gameserverid;
        return sellerid;
    });

    const gameandserverid = gameid + ',' + gameserverid;
    const orgids = state.purchaseListIdsByGameAndServerId[gameandserverid] || [];

    const newids = uniq([...ids, ...orgids]);
    const newpurchaseListIdsByGameAndServerId = Object.assign({}, state.purchaseListIdsByGameAndServerId, {[gameandserverid]: newids});
    return Object.assign({}, state, {
        purchaseListIdsByGameAndServerId: newpurchaseListIdsByGameAndServerId,
        purchaseMap: newItemMap,
        isloading: false,
    });
    
}


function listPurchasesSuccess (state, action) {
    const dbresult = convetDBResultSetToArray(action.payload.result.list);
    if(dbresult.length === 0) {
        return Object.assign({}, state, {
            isloading: false,
        });
    }
    
    const grade = convetDBResultSetToArray(action.payload.result.grades);
    const { gameid, gameserverid } = action.payload;



    const itemMap = dbresult.reduce((map, item, idx)=>{
        const sellerid = item.viprovid + ',' + item.gameid + ',' +item.gameserverid;
        const orgmap = state.purchaseMap[sellerid] || {};
        map[sellerid] = Object.assign({}, orgmap, item, {sellerid, ...grade[idx]});
        return map;
    }, {});
    
    const newItemMap = Object.assign({}, state.purchaseMap, itemMap);

    const ids = dbresult.map((item, idx)=>{
        const sellerid = item.viprovid + ',' + item.gameid + ',' +item.gameserverid;
        return sellerid;
    });

    const gameandserverid = gameid + ',' + gameserverid;
    const orgids = state.purchaseListIdsByGameAndServerId[gameandserverid] || [];

    const newids = uniq([...orgids, ...ids]);
    const newpurchaseListIdsByGameAndServerId = Object.assign({}, state.purchaseListIdsByGameAndServerId, {[gameandserverid]: newids});
    return Object.assign({}, state, {
        purchaseListIdsByGameAndServerId: newpurchaseListIdsByGameAndServerId,
        purchaseMap: newItemMap,
        isloading: false,
    });
    
}



// sellerMap: {},
// sellerListIdsByGameAndServerId: {},

function getVItemTopSellerListForGameSuccess (state, action) {
    const dbresult = convetDBResultSetToArray(action.payload.result);
    const { gameid, gameserverid } = action.payload;



    const itemMap = dbresult.reduce((map, item)=>{
        const sellerid = item.viprovid + ',' + item.gameid + ',' +item.gameserverid;
        const orgmap = state.sellerMap[sellerid] || {};
        map[sellerid] = Object.assign({}, orgmap, item, {sellerid});
        return map;
    }, {});
    
    const newItemMap = Object.assign({}, state.sellerMap, itemMap);

    const ids = dbresult.map((item, idx)=>{
        const sellerid = item.viprovid + ',' + item.gameid + ',' +item.gameserverid;
        return sellerid;
    });

    const gameandserverid = gameid + ',' + gameserverid;
    const orgids = state.sellerListIdsByGameAndServerId[gameandserverid] || [];

    const newids = uniq([...ids, ...orgids]);
    const newsellerListIdsByGameAndServerId = Object.assign({}, state.sellerListIdsByGameAndServerId, {[gameandserverid]: newids});
    return Object.assign({}, state, {
        sellerListIdsByGameAndServerId: newsellerListIdsByGameAndServerId,
        sellerMap: newItemMap,
        isloading: false,
    });
    
}


function getVItemSellerListForGameSuccess (state, action) {
    const dbresult = convetDBResultSetToArray(action.payload.result);
    const { gameid, gameserverid } = action.payload;



    const itemMap = dbresult.reduce((map, item)=>{
        const sellerid = item.viprovid + ',' + item.gameid + ',' +item.gameserverid;
        const orgmap = state.sellerMap[sellerid] || {};
        map[sellerid] = Object.assign({}, orgmap, item, {sellerid});

        return map;
    }, {});
    
    const newItemMap = Object.assign({}, state.sellerMap, itemMap);

    const ids = dbresult.map((item, idx)=>{
        const sellerid = item.viprovid+ ',' + item.gameid + ',' +item.gameserverid;
        return sellerid;
    });

    const gameandserverid = gameid + ',' + gameserverid;
    const orgids = state.sellerListIdsByGameAndServerId[gameandserverid] || [];

    const newids = uniq([...orgids, ...ids]);
    const newsellerListIdsByGameAndServerId = Object.assign({}, state.sellerListIdsByGameAndServerId, {[gameandserverid]: newids});
    return Object.assign({}, state, {
        sellerListIdsByGameAndServerId: newsellerListIdsByGameAndServerId,
        sellerMap: newItemMap,
        isloading: false,
    });
}

function initOrderSuccess (state, action) {
    const dbresult = convetDBResultSetToArray(action.payload.result);
    const { gameid, gameserverid, viprovid } = action.payload;



    const itemMap = dbresult.reduce((map, item)=>{
        const sellerid = viprovid + ',' + gameid + ',' +gameserverid;
        const orgmap = state.sellerMap[sellerid] || {};
        map[sellerid] = Object.assign({}, orgmap, item, {sellerid});
        return map;
    }, {});
    
    const newItemMap = Object.assign({}, state.sellerMap, itemMap);
    return Object.assign({}, state, {
        sellerMap: newItemMap,
        isloading: false,
    });
}

function buildVItemCartOrderSuccess (state, action) {
    
    const cartOrder = action.payload;

    return Object.assign({}, state, {
        cartOrder,
        isloading: false,
	});
}

function payVItemCartSuccess (state, action) {
    const cartOrder = action.payload;

    return Object.assign({}, state, {
        cartOrder,
        isloading: false,
	});
}


// 操作失敗
function actionFailed (state, action) {

    if(state.error === action.payload){
        return state;
    }

    const error = action.payload;

    return Object.assign({}, state, {
        isloading: false,
        error,
	});


}


// 操作中
function actionStarted (state, action) {

    if(state.isloading){
        return state;
    }

    return Object.assign({}, state, {
        isloading: true,
	});


}

function addPurchaseFormData(state, action) {

    const purchaseFormData = action.payload.purchaseFormData;
    
    return Object.assign({}, state, {
        purchaseFormData,
	});
}


/*==========================
    Export
==========================*/
export default function createReducers (initialState) {
	return function reducer(state = initialState, action) {
        if(!actionTypes.hasOwnProperty(action.type)){
            return state;
        }
		if (reducers.hasOwnProperty(action.type)) {
            return reducers[action.type](state, action);
		} else if(action.type.lastIndexOf('Started') !== -1){
			return actionStarted(state, action);            
		} else if(action.type.lastIndexOf('Failed') !== -1){
			return actionFailed(state, action);            
        } else {
			return state;
		}
	};
}