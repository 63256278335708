/*=====================================
    全域顏色樣式

    Author: Gray
    createtime: 2017 / 12 / 21
=====================================*/

/*--------------------------
    Style Variables
--------------------------*/
const MAIN_COLOR = '#F75C2F';
const Red = '#F14342';
const Yellow = '#EEE552';
const Orange = '#F19840';
const Blue = '#2E7AC3';
const BlueLight = '#14A5FF';
const Green = '#008675';
const DarkGreen = '#009688';
const Purple = '#443AB7';

const Success = '#008675';
const Error = '#F14342';

const Dark = '#000';
const Dark2 = '#333333';
const Dark3 = '#4A4A4A';
const Dark4 = '#6a6a6a';
const Dark5 = '#9B9B9B';

const ImgBackground = '#DDD';

// 轉換公式
function hex(x) {
	return ("0" + parseInt(x, 10).toString(16)).slice(-2);
}

// 1 ~ 100 變亮
// -1 ~ -100 變暗
function LightenDarkenColor(col, amt) {
  
    var usePound = false;
  
    if (col[0] === "#") {
        col = col.slice(1);
        usePound = true;
    }
 
    var num = parseInt(col,16);
 
    var r = (num >> 16) + amt;
 
    if (r > 255) r = 255;
    else if  (r < 0) r = 0;
 
    var b = ((num >> 8) & 0x00FF) + amt;
 
    if (b > 255) b = 255;
    else if  (b < 0) b = 0;
 
    var g = (num & 0x0000FF) + amt;
 
    if (g > 255) g = 255;
    else if (g < 0) g = 0;

    const _hex = (g | (b << 8) | (r << 16)).toString(16);
    if(_hex.length !== 6) {
    	return (usePound?"#":"") + (hex(r) + hex(g) + hex(b));
    } else {
    	return (usePound?"#":"") + _hex;
    }
}

function RGBA(hex, opacity) {

	// http://stackoverflow.com/a/5624139
  	var shorthandRegex = /^#?([a-f\d])([a-f\d])([a-f\d])$/i;
  	hex = hex.replace(shorthandRegex, function (m, r, g, b) {
    	return r + r + g + g + b + b;
  	});

  	var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
  	result = result ? 'rgba(' + parseInt(result[1], 16) + ',' + parseInt(result[2], 16) + ',' + parseInt(result[3], 16) + ',' + opacity + ')' : null;
  	return result;
}

const Colors = {
    MAIN_COLOR,
	Red,
	Yellow,
	Orange,
	Blue,
	BlueLight,
	Green,
	DarkGreen,
	Success,
	Error,
	Dark,
	Dark2,
	Dark3,
    Dark4,
    Dark5,
	Purple,
    ImgBackground,

	LightenDarkenColor,
	RGBA,
}

export default Colors;