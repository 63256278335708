/*=====================================
    Cupoy Router View

    Author: Gray
    createtime: 2017 / 10 / 26
=====================================*/

/*--------------------------
    Import
--------------------------*/
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import actionCreators from "actions/creators";
import CupoyRouter from "components/commons/router/CupoyRouter";

/*--------------------------
    Main Component
--------------------------*/
class CupoyRouterView extends PureComponent {

    // ------------------------------
    // componentWillMount
    // ------------------------------
    componentWillMount() {
        CupoyRouter.updateCurrent(this.props.history.location, this.props.history.action);

        if(CupoyRouter.isUnknowState()) {
            this.props.webActions.cupoyStateGoOtherwise();
        }

        // 不是Route物件內的components不會感覺到history的變動，所以必須監聽事件，
        // 但是listen事件沒有辦法移除，所以會變成會一直 componentDidMount 跟 componentWillUnmount 的 components 不能執行這個動作
        this.props.history.listen((location, action) => {
            CupoyRouter.updateCurrent(location, action);

            if(CupoyRouter.isUnknowState()) {
                this.props.webActions.cupoyStateGoOtherwise();
            }
        });
    }

    // ------------------------------
    // render
    // ------------------------------
    render() {
        return (<span>{ this.props.children }</span>);
    }
}

/*--------------------------
    Reducer Props
--------------------------*/
const mapStateToProps = function(state) {
    return {
        history: state.web.history,
    };
};

/*--------------------------
    Reducer Action
--------------------------*/
const mapActionToProps = function(dispatch) {
    return {
        webActions: bindActionCreators(actionCreators.webActionCreators, dispatch),
        
    };
};

/*--------------------------
    export
--------------------------*/
export default connect(mapStateToProps, mapActionToProps, null, {
    forwardRef: true
})(CupoyRouterView);