import {uniq} from 'lodash';
import actionTypes from "actions/types/cardOrderActionTypes";
import convetDBResultSetToArray from "actions/util/convetDBResultSetToArray";

/*==========================
    Reducers
==========================*/
const reducers = {
    [actionTypes.listCardOrdersSuccess]: function(state, action) {
        return listCardOrdersSuccess(state, action);
    },
    [actionTypes.qyCardOrderDetailSuccess]: function(state, action) {
        return qyCardOrderDetailSuccess(state, action);
    },
    [actionTypes.setCardUsedFlagSuccess]: function(state, action) {
        return setCardUsedFlagSuccess(state, action);
    },
    [actionTypes.listBonusOrdersSuccess]: function(state, action) {
        return listBonusOrdersSuccess(state, action);
    },
    [actionTypes.buyFreeCardProductSuccess]: function(state, action) {
        return buyFreeCardProductSuccess(state, action);
    },

};





/*==========================
    Method
==========================*/

function listCardOrdersSuccess (state, action) {

    const {
        cartid,
        result,
        refresh,
    } = action.payload;

    const dbresult = convetDBResultSetToArray(result);

    const orderMap = dbresult.reduce((map, order)=>{
        map[order.orderid] = Object.assign({}, state.orderMap[order.orderid], order);
        return map;
    }, {});
    

    const append_ids = dbresult.map(order => {
        return order.orderid;
    });

    var key = cartid ? cartid : 'all';

    const orderIdsMap = state.orderIdsMap;
    const old_ids = orderIdsMap[key] ? orderIdsMap[key] : [];

    const new_ids = refresh ? append_ids : uniq([...old_ids, ...append_ids]);

    const new_orderIdsMap = Object.assign({}, orderIdsMap, {
        [key]: new_ids,
    });

    const newOrderMap = Object.assign({}, state.orderMap, orderMap);

    return Object.assign({}, state, {
        orderIdsMap: new_orderIdsMap,
        orderMap: newOrderMap,
        isloading: false,
    });
}

function qyCardOrderDetailSuccess (state, action) {
    let {orderid} = action.payload;
    
    const jcarditems = action.payload.result.list;

    if(!orderid){
        orderid = jcarditems[0].orderid;
    }


    const ids = jcarditems.map((jcarditem, idx)=>{
        return jcarditem.jcardid;
    });    

    const orgids = state.jcardIdsByOrderId[orderid] || [];
    
    const newJcardIds = [...new Set([...orgids, ...ids])];

    const newJcardIdsByOrderId = Object.assign({}, state.jcardIdsByOrderId, {[orderid]: newJcardIds});

    const jcardMap = jcarditems.reduce((map, jcarditem)=>{
        map[jcarditem.jcardid] = Object.assign({}, state.jcardMap[jcarditem.jcardid], jcarditem);
        return map;
    }, {});

    const newJcardMap = Object.assign({}, state.jcardMap, jcardMap);



    return Object.assign({}, state, {
        jcardMap: newJcardMap,
        jcardIdsByOrderId: newJcardIdsByOrderId,
        isloading: false,
	});
}

function setCardUsedFlagSuccess (state, action) {
    
    const {jcardid, used} = action.payload;

    if(action.payload.result){

        const newjcard = Object.assign({}, state.jcardMap[jcardid], {isused: used});

        const newjcardmap = Object.assign({}, state.jcardMap, {[jcardid]: newjcard});
        
        return Object.assign({}, state, {
            jcardMap: newjcardmap,
            isloading: false,
        });
    }
    
    return Object.assign({}, state, {
        isloading: false,
    });
}

function listBonusOrdersSuccess (state, { payload }) {

    const {
        result,
        refresh,
    } = payload;

    const dbresult = convetDBResultSetToArray(result);

    const orderMap = dbresult.reduce((map, order)=>{
        map[order.orderid] = Object.assign({}, state.orderMap[order.orderid], order);
        return map;
    }, {});
    

    const ids = dbresult.map((order, idx)=>{
        return order.orderid;
    });

    const newids = refresh ? ids : uniq([...state.bonusOrderIds, ...ids]);

    const newOrderMap = Object.assign({}, state.orderMap, orderMap);

    return Object.assign({}, state, {
        bonusOrderIds: newids,
        orderMap: newOrderMap,
        isloading: false,
    });
}

function buyFreeCardProductSuccess (state, action) {
    
    return Object.assign({}, state, {
        isloading: false,
    });
}


// 操作失敗
function actionFailed (state, action) {

    if(state.error === action.payload){
        return state;
    }

    const error = action.payload;

    return Object.assign({}, state, {
        isloading: false,
        error,
	});


}


// 操作中
function actionStarted (state, action) {

    if(state.isloading){
        return state;
    }

    return Object.assign({}, state, {
        isloading: true,
	});


}


/*==========================
    Export
==========================*/
export default function createReducers (initialState) {
	return function reducer(state = initialState, action) {
        if(!actionTypes.hasOwnProperty(action.type)){
            return state;
        }
		if (reducers.hasOwnProperty(action.type)) {
            return reducers[action.type](state, action);
		} else if(action.type.lastIndexOf('Started') !== -1){
			return actionStarted(state, action);            
		} else if(action.type.lastIndexOf('Failed') !== -1){
			return actionFailed(state, action);            
        } else {
			return state;
		}
	};
}