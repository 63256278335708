/*=====================================
    按鈕共用樣式

    Author: Gray
    createtime: 2017 / 12 / 25
=====================================*/

/*--------------------------
    Import 
--------------------------*/
import styled from 'styled-components';
import Colors from './colors';

/*--------------------------
    Style Variables
--------------------------*/

/*--------------------------
    Style Component
--------------------------*/
const BaseButton = styled.div`
    display: inline-block;
    height: 40px;
    margin: 0;
    padding: 0 20px;
    border-radius: 3px;
    text-align: center;
    vertical-align: middle;
    font-size: 14px;
    color: #6a6a6a;
    transition: all 250ms cubic-bezier(0.26, 0.094, 0.795, 1.04);
    transition-timing-function: cubic-bezier(0.26, 0.094, 0.795, 1.04);

    ${(props) => {
        var result = ``;

        if(props.disable) {
            result += `
                cursor: default;
                opacity: 0.5;
            `;
        } else {
            result += `
                cursor: pointer;
                opacity: 1;
            `;
        }

        if(props.outline) {
            result += `
                line-height: 38px;
                border: 1px solid #eee;
                background-color: transparent;

                &:hover {
                    color: #000;
                    border: 1px solid #6a6a6a;
                    background-color: transparent;
                }
            `;
        } else {
            result += `
                line-height: 40px;
                border: none;
                background-color: #eee;

                &:hover {
                    color: #000;
                    border: none;
                    background-color: transparent;
                }
            `;
        }

        return result;
    }}
`;

const PurpleButton = BaseButton.extend`
    
    ${(props) => {
        var result = ``;

        if(props.outline) {
            result += `
                line-height: 38px;
                border: 1px solid ${Colors.Purple};
                background-color: transparent;
                color: ${Colors.Purple};

                &:hover {
                    color: ${Colors.Purple2};
                    border: 1px solid ${Colors.Purple2};
                    background-color: transparent;
                }
            `;
        } else {
            result += `
                line-height: 40px;
                border: none;
                background-color: ${Colors.Purple};
                color: #FFF;

                &:hover {
                    color: #fff;
                    border: none;
                    background-color: ${Colors.Purple2};
                }
            `;
        }

        return result;
    }}
`;

const RedButton = BaseButton.extend`
    
    ${(props) => {
        var result = ``;

        if(props.outline) {
            result += `
                line-height: 38px;
                border: 1px solid ${Colors.Red};
                background-color: transparent;
                color: ${Colors.Red};

                &:hover {
                    color: ${Colors.LightenDarkenColor(Colors.Red, 10)};
                    border: 1px solid ${Colors.LightenDarkenColor(Colors.Red, 10)};
                    background-color: transparent;
                }
            `;
        } else {
            result += `
                line-height: 40px;
                border: none;
                background-color: ${Colors.Red};
                color: #FFF;

                &:hover {
                    color: #fff;
                    border: none;
                    background-color: ${Colors.LightenDarkenColor(Colors.Red, 10)};
                }
            `;
        }

        return result;
    }}
`;

const BlueButton = BaseButton.extend`
    
    ${(props) => {
        var result = ``;

        if(props.outline) {
            result += `
                line-height: 38px;
                border: 1px solid ${Colors.Blue};
                background-color: transparent;
                color: ${Colors.Blue};

                &:hover {
                    color: ${Colors.LightenDarkenColor(Colors.Blue, 10)};
                    border: 1px solid ${Colors.LightenDarkenColor(Colors.Blue, 10)};
                    background-color: transparent;
                }
            `;
        } else {
            result += `
                line-height: 40px;
                border: none;
                background-color: ${Colors.Blue};
                color: #FFF;

                &:hover {
                    color: #fff;
                    border: none;
                    background-color: ${Colors.LightenDarkenColor(Colors.Blue, 10)};
                }
            `;
        }

        return result;
    }}
`;

const Button = {
    Base: BaseButton,
    Purple: PurpleButton,
    Red: RedButton,
    Blue: BlueButton,
}

export default Button;
