export default {
    listBonusLogs: 'listBonusLogs',
    listBonusLogsStarted: 'listBonusLogsStarted',
    listBonusLogsSuccess: 'listBonusLogsSuccess',
    listBonusLogsFailed: 'listBonusLogsFailed',

    depositBonusPaidCard: 'depositBonusPaidCard',
    depositBonusPaidCardStarted: 'depositBonusPaidCardStarted',
    depositBonusPaidCardSuccess: 'depositBonusPaidCardSuccess',
    depositBonusPaidCardFailed: 'depositBonusPaidCardFailed',

};
