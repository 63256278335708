import actionTypes from "actions/types/bonusActionTypes";
import convetDBResultSetToArray from "actions/util/convetDBResultSetToArray";

/*==========================
    Reducers
==========================*/
const reducers = {
    [actionTypes.listBonusLogsSuccess]: function(state, action) {
        return listBonusLogsSuccess(state, action);
    },
    [actionTypes.depositBonusPaidCardSuccess]: function(state, action) {
        return depositBonusPaidCardSuccess(state, action);
    },
};




/*==========================
    Method
==========================*/

function listBonusLogsSuccess (state, { payload }) {

    const {
        result,
        refresh,
    } = payload;
    
    const dbresult = convetDBResultSetToArray(result);

    let newlogs = refresh ? dbresult : [...state.logs, ...dbresult];

    const [_newlogs] = newlogs.reduceRight(
        (acc, log) => {

            const { itemid, createtime } = log;
            const key = `${itemid}${createtime}`;
            const [result, keys] = acc;

            if (!keys.includes(key)) {
                keys.push(key);
                result.unshift({...log, key});
            }

            return acc;
        },
        [[], []]
    );

    return Object.assign({}, state, {
        logs: _newlogs,
        isloading: false,
	});
}

function depositBonusPaidCardSuccess (state, action) {
    return Object.assign({}, state, {
        logs: [],
        isloading: false,
	});
}


// 操作失敗
function actionFailed (state, action) {

    if(state.error === action.payload){
        return state;
    }

    const error = action.payload;

    return Object.assign({}, state, {
        isloading: false,
        error,
	});


}


// 操作中
function actionStarted (state, action) {

    if(state.isloading){
        return state;
    }

    return Object.assign({}, state, {
        isloading: true,
	});


}


/*==========================
    Export
==========================*/
export default function createReducers (initialState) {
	return function reducer(state = initialState, action) {
        if(!actionTypes.hasOwnProperty(action.type)){
            return state;
        }
		if (reducers.hasOwnProperty(action.type)) {
            return reducers[action.type](state, action);
		} else if(action.type.lastIndexOf('Started') !== -1){
			return actionStarted(state, action);            
		} else if(action.type.lastIndexOf('Failed') !== -1){
			return actionFailed(state, action);            
        } else {
			return state;
		}
	};
}