/*=====================================
    CupoyImage

    Author: Gray
    createtime: 2017 / 12 / 25 (copy from cupoy)
=====================================*/

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

var cache = new Map();

class CupoyImage extends Component {
    static propTypes = {
        src: PropTypes.string.isRequired,
        placeholderImgUrl: PropTypes.string,
        onLoaded: PropTypes.func,
        fallbackCallback: PropTypes.func,
        fallbackFailCallback: PropTypes.func,
        fallbackImageUrl: PropTypes.string,
        minSize: PropTypes.number,
        isBackgroundImage: PropTypes.bool,
        className: PropTypes.string,
        style: PropTypes.object,
        children: PropTypes.node
    };

    constructor(props) {
        super(props);

        this.state = {
            loaded: false,
            error: false,
            fallbacked: false,
            src: this.props.src
        };

        this.handleLoad = this.handleLoad.bind(this);
        this.handleError = this.handleError.bind(this);
        this.loadFallbackOrFail = this.loadFallbackOrFail.bind(this);

        var ImgStylecomponent;

        if (this.props.isBackgroundImage) {
            if (this.props.stylecomponent) {
                ImgStylecomponent = this.props.stylecomponent;
            } else {
                ImgStylecomponent = styled.div``;
            }
        } else {
            if (this.props.stylecomponent) {
                ImgStylecomponent = this.props.stylecomponent.withComponent(
                    'img'
                );
            } else {
                ImgStylecomponent = styled.img``;
            }
        }

        this.ImgStylecomponent = ImgStylecomponent;
    }

    componentDidMount() {
        if (!cache.has(this.props.src)) {
            // cache無資料開始預載
            this.image = new Image();

            this.image.src = this.props.src;
            this.image.onload = this.handleLoad;
            this.image.onerror = this.handleError;
        } else {
            this.setState(
                {
                    loaded: true,
                    src: cache.get(this.props.src)
                },
                () => {
                    // 檢查是否跟原始url一致，若不一致，也要呼叫fallback callback
                    if (this.props.src !== this.state.src) {
                        if (
                            this.state.src === 'about:blank' ||
                            this.state.src === this.props.placeholderImgUrl
                        ) {
                            this.props.fallbackFailCallback &&
                                this.props.fallbackFailCallback();
                        } else {
                            this.props.fallbackCallback &&
                                this.props.fallbackCallback();
                        }
                    }
                }
            );
        }
    }

    componentWillReceiveProps(nextProps) {

        if (nextProps.src !== this.props.src) {
            this.setState(
                {
                    loaded: false
                },
                () => {
                    if (!cache.has(nextProps.src)) {
                        // cache無資料開始預載
                        this.image = new Image();

                        this.image.src = nextProps.src;
                        this.image.onload = this.handleLoad;
                        this.image.onerror = this.handleError;
                    } else {
                        this.setState({
                            loaded: true,
                            src: cache.get(nextProps.src)
                        });
                    }
                }
            );
        }
    }

    shouldComponentUpdate(nextProps, nextState) {

        return !this.state.loaded;
    }

    componentWillUnmount() {
        if (this.image) {
            this.image.onerror = null;
            this.image.onload = null;
            this.image = null;
        }
    }

    loadFallbackOrFail() {
        if (!this.state.fallbacked && this.props.fallbackImageUrl) {
            // 載入fallback url再給一次機會
            this.setState(
                {
                    fallbacked: true
                },
                () => {
                    this.image.src = this.props.fallbackImageUrl;
                    this.props.fallbackCallback &&
                        this.props.fallbackCallback();
                }
            );
        } else {
            this.setState(
                {
                    error: true
                },
                () => {
                    this.props.fallbackFailCallback &&
                        this.props.fallbackFailCallback();
                }
            );
            cache.set(
                this.props.src,
                this.props.placeholderImgUrl || 'about:blank'
            );
            // console.log(cache);
        }
    }

    handleLoad(e) {
        if (this.props.minSize) {
            // 檢查圖片大小是否過小
            const size = this.image.naturalHeight * this.image.naturalWidth;
            if (size < this.props.minSize) {
                // fallback
                // console.log('image size too small, fallback or fail...');
                this.loadFallbackOrFail();
                return;
            }
        }

        this.props.onLoaded &&
            this.props.onLoaded({
                width: this.image.naturalWidth,
                height: this.image.naturalHeight
            });

        var newState = {};

        newState.loaded = true;

        if(this.image) {
            newState.src = this.image.src;
        }

        this.setState(newState);
        
        if(this.props && this.image)
            cache.set(this.props.src, this.image.src);
    }

    handleError(e) {
        // console.error('Failed to load ', this.props.src);
        this.loadFallbackOrFail();
    }
    render() {
        const { placeholderImgUrl, children, style, className, alt } = this.props;
        const source = !this.state.loaded || this.state.error
            ? placeholderImgUrl
            : this.state.fallbacked
                  ? this.props.fallbackImageUrl
                  : this.state.src;

        if (this.props.isBackgroundImage) {
            const newStyle = Object.assign({}, style, {
                backgroundImage: `url(${source})`
            });
            
            return (
                <this.ImgStylecomponent
                    style={newStyle}
                    className={className}
                    onClick={this.props.onClick}
                >
                    {children}
                </this.ImgStylecomponent>
            );
        } else {
            return (
                <this.ImgStylecomponent
                    style={style}
                    src={source}
                    className={className}
                    alt={alt}
                    onClick={this.props.onClick}
                />
            );
        }
    }
}

export default CupoyImage;
