import actionTypes from "actions/types/idxPageActionTypes";
import convetDBResultSetToArray from "actions/util/convetDBResultSetToArray";

/*==========================
    Reducers
==========================*/
const reducers = {
	// 接收首頁資訊
	[actionTypes.getIdxPageInfoStarted]: function(state, action) {
		return getIdxPageInfoStarted(state, action);
	},
	[actionTypes.setIdxPageInfo]: function(state, action) {
		return setIdxPageInfo(state, action);
	},
	[actionTypes.getIdxPageInfoFailed]: function(state, action) {
		return getIdxPageInfoFailed(state, action);
	},
	
	// 接收首頁廣告
	[actionTypes.setTopADList]: function(state, action) {
		return setTopADList(state, action);
	},

	// 接收首頁抽獎結果
	[actionTypes.getIdxPageLotteryResultSuccess]: function(state, action) {
		return getIdxPageLotteryResultSuccess(state, action);
	},
};

/*==========================
    Method
==========================*/

function getIdxPageLotteryResultSuccess (state, action) {
	
	// 抽獎結果
	const lotterys = convetDBResultSetToArray(action.payload.result.lotterys);


	return Object.assign({}, state, {
		lotterys,
		isLoadingIdxPageInfo: false,
		isLoadIdxPageInfoFailed: false,
	});
}

// ------------------------------
// 接收首頁資訊
// ------------------------------
function getIdxPageInfoStarted (state, action) {
	return Object.assign({}, state, {
		isLoadingIdxPageInfo: true
	});
}
function setIdxPageInfo (state, action) {

	// 轉換首頁資訊
	
	// 最新消息		
	const bulletin1s = action.payload.bulletin1s ? action.payload.bulletin1s[0].map((title, idx)=> {

		const bulletinid = action.payload.bulletin1s[1][idx];
		const publishtime = action.payload.bulletin1s[2][idx];
		const topbulletin = action.payload.bulletin1s[3][idx];
		const bulletin = {
			title,
			bulletinid, 
			publishtime,
			topbulletin,
		}
		return bulletin;
	}) : null;

	// 商品交易		
	
	const bulletin2s = action.payload.bulletin2s ? action.payload.bulletin2s[0].map((title, idx)=> {

		const bulletinid = action.payload.bulletin2s[1][idx];
		const publishtime = action.payload.bulletin2s[2][idx];
		const topbulletin = action.payload.bulletin2s[3][idx];
		const bulletin = {
			title,
			bulletinid, 
			publishtime,
			topbulletin,

		}
		return bulletin;
	}) : null ;	

	// 熱門遊戲
	const hotgames = action.payload.hotgames ? action.payload.hotgames[0].map((gameid, idx)=> {

		const gamename = action.payload.hotgames[1][idx];
		const idximg = action.payload.hotgames[2][idx];
		const gamelink = action.payload.hotgames[3][idx];
		const game = {
			gameid,
			gamename, 
			idximg,
			gamelink,
		}
		return game;
	}) : null;	
	// 熱門商品
	const hotcards = action.payload.hotcards ? action.payload.hotcards[0].map((productname, idx)=> {

		const agencyid = action.payload.hotcards[1][idx];
		const productid = action.payload.hotcards[2][idx];
		const productclassid = action.payload.hotcards[3][idx];
		const thumbimg = action.payload.hotcards[4][idx];
		const marketprice = action.payload.hotcards[5][idx];
		const price = action.payload.hotcards[6][idx];
		const card = {
			productname,
			agencyid, 
			productid,
			productclassid,
			thumbimg,
			marketprice,
			price,

		}
		return card;
	}) : null;	
	//推薦發財商城
	const hotbonuscards = action.payload.hotbonuscards ? action.payload.hotbonuscards[0].map((productname, idx)=> {

		const agencyid = action.payload.hotbonuscards[1][idx];
		const productid = action.payload.hotbonuscards[2][idx];
		const productclassid = action.payload.hotbonuscards[3][idx];
		const thumbimg = action.payload.hotbonuscards[4][idx];
		const card = {
			productname,
			agencyid, 
			productid,
			productclassid,
			thumbimg,

		}
		return card;
	}) : null ;

	const newproducts = action.payload.newproducts ? action.payload.newproducts[0].map((productname, idx)=> {

		const agencyid = action.payload.newproducts[1][idx];
		const productid = action.payload.newproducts[2][idx];
		const productclassid = action.payload.newproducts[3][idx];
		const thumbimg = action.payload.newproducts[4][idx];

		const product = {
			productname,
			agencyid, 
			productid,
			productclassid,
			thumbimg,
		};

		return product;
	}) : null ;


	return Object.assign({}, state, {
		bulletin1s,
		bulletin2s,
		hotgames,
		hotcards,
		hotbonuscards,
		newproducts,
		isLoadingIdxPageInfo: false,
		isLoadIdxPageInfoFailed: false,
		isLoadedIdxPageInfo: true,
	});
}
function getIdxPageInfoFailed(state, action) {
	return Object.assign({}, state, {
		isLoadingIdxPageInfo: false,
		isLoadIdxPageInfoFailed: true,
	});
}

function setTopADList(state, action){

	const topads = convetDBResultSetToArray(action.payload);

	if(state.topads === topads){
		return state;
	}

	return Object.assign({}, state, {
		topads,
		isLoadedIdxPageTopads: true,
	});



}

/*==========================
    Export
==========================*/
export default function createReducers (initialState) {
	return function reducer(state = initialState, action) {
		if(!actionTypes.hasOwnProperty(action.type)){
            return state;
        }
		if (reducers.hasOwnProperty(action.type)) {
			return reducers[action.type](state, action);
		} else {
			return state;
		}
	};
}