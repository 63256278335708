import GlobalStyle from './globalstyle';
import Colors from './colors';
import styled from 'styled-components';

const Container = GlobalStyle.BasicBlock.extend`
    padding: 30px;
    color: ${Colors.Dark3};
`;
const Title = styled.div`
    font-size: 16px;
    font-weight: bold;
    margin-bottom: 20px;
`;
const List = styled.ul`
    line-height: 22px;
    font-size: 14px;
`;
const SubList = List.extend`
    margin-top: 20px;
`;
const Row = styled.li`
    display: flex;
    flex-direction: row;
    text-align: justify;

    ${
        ({ listType, number }) => listType === 'number' ?
        `
        &::before {
            display: block;
            content: '${number}.';
            margin-right: 6px;
        }
        `
        :
        `
        &::before {
            display: block;
            content: '';
            min-width: 6px;
            width: 6px;
            height: 6px;
            margin-top: 6px;
            margin-right: 6px;
            background-color: ${Colors.Dark3};
        }
        `
    };

    &:not(:last-child) {
        margin-bottom: 20px;
    }
`;
const Section = styled.div`
    margin-top: 20px;
`;
const Description = styled.div`
    font-size: 14px;
    line-height: 22px;
`;

const AboutStyle = {
    Container,
    Title,
    List,
    SubList,
    Row,
    Section,
    Description,
};

export default AboutStyle;