import {uniq} from 'lodash';
import actionTypes from "actions/types/bulletinActionTypes";
import convetDBResultSetToArray from "actions/util/convetDBResultSetToArray";

/*==========================
    Reducers
==========================*/
const reducers = {
    [actionTypes.listBulletinsSuccess]: function(state, action) {
        return listBulletinsSuccess(state, action);
    },
    [actionTypes.getBulletinDetailSuccess]: function(state, action) {
        return getBulletinDetailSuccess(state, action);
    },

};



/*==========================
    Method
==========================*/

function listBulletinsSuccess (state, action) {
    const bclass = action.payload.bclass;
    const bulletinsarr = convetDBResultSetToArray(action.payload);
    const bulletins = bulletinsarr.reduce((map, bulletin)=>{
        map[bulletin.bulletinid] = Object.assign({}, state.itemMap[bulletin.bulletinid], bulletin);
        return map;
    }, {});
    

    const ids = bulletinsarr.map((bulletin, idx)=>{
        return bulletin.bulletinid;
    });


    var oldids = state.idsbyclass[bclass];

    if(!Array.isArray(oldids)) {
        oldids = [];
    }

    const newids = { [bclass]: uniq([...oldids, ...ids])};
    const newidsbyclass = Object.assign({}, state.idsbyclass, newids);

    const newbulletins = Object.assign({}, state.itemMap, bulletins);

    return Object.assign({}, state, {
        idsbyclass: newidsbyclass,
        itemMap: newbulletins,
        isloading: false,
	});
}

function getBulletinDetailSuccess (state, action) {
    
    const bulletin = convetDBResultSetToArray(action.payload)[0];
    
    if(!bulletin) return state;

    const newbulletins = Object.assign({}, state.itemMap, {[bulletin.bulletinid]: bulletin});

    return Object.assign({}, state, {
        itemMap: newbulletins,
        isloading: false,
	});

}



// 操作失敗
function actionFailed (state, action) {

    if(state.error === action.payload){
        return state;
    }

    const error = action.payload;

    return Object.assign({}, state, {
        isloading: false,
        error,
	});


}


// 操作中
function actionStarted (state, action) {

    if(state.isloading){
        return state;
    }

    return Object.assign({}, state, {
        isloading: true,
	});


}


/*==========================
    Export
==========================*/
export default function createReducers (initialState) {
	return function reducer(state = initialState, action) {
        if(!actionTypes.hasOwnProperty(action.type)){
            return state;
        }        
		if (reducers.hasOwnProperty(action.type)) {
            return reducers[action.type](state, action);
		} else if(action.type.lastIndexOf('Started') !== -1){
			return actionStarted(state, action);            
		} else if(action.type.lastIndexOf('Failed') !== -1){
			return actionFailed(state, action);            
        } else {
			return state;
		}
	};
}