import actionTypes from "actions/types/cardActionTypes";
import convetDBResultSetToArray from "actions/util/convetDBResultSetToArray";
import {uniq} from 'lodash';
/*==========================
    Reducers
==========================*/
const reducers = {
    [actionTypes.listCardProductsStarted]: function(state, action) {
        return listCardProductsStarted(state, action);
    },
    [actionTypes.listCardProductsSuccess]: function(state, action) {
        return listCardProductsSuccess(state, action);
    },
    [actionTypes.listCardProductsFailed]: function(state, action) {
        return listCardProductsFailed(state, action);
    },
    [actionTypes.getProductGroupsStarted]: function(state, action) {
        return getProductGroupsStarted(state, action);
    },
    [actionTypes.getProductGroupsSuccess]: function(state, action) {
        return getProductGroupsSuccess(state, action);
    },
    [actionTypes.getProductGroupsFailed]: function(state, action) {
        return getProductGroupsFailed(state, action);
    },
    [actionTypes.listFreeProductsSuccess]: function(state, action) {
        return listFreeProductsSuccess(state, action);
    },
    [actionTypes.listVitemProductsSuccess]: function(state, action) {
        return listVitemProductsSuccess(state, action);
    },
    [actionTypes.listBonusProductsSuccess]: function(state, action) {
        return listBonusProductsSuccess(state, action);
    },
    [actionTypes.getProductInfoSuccess]: function(state, action) {
        return getProductInfoSuccess(state, action);
    },
    [actionTypes.listRecommendedProductsSuccess]: function(state, action) {
        return listRecommendedProductsSuccess(state, action);
    },
    [actionTypes.getIbonBlacklistSuccess]: function(state, action) {
        return getIbonBlacklistSuccess(state, action);
    },
};



/*==========================
    Method
==========================*/

function listCardProductsSuccess (state, action) {
    const dbresult = convetDBResultSetToArray(action.payload.result);

    const cardProductMap = dbresult.reduce((map, item)=>{
        map[item.productid] = Object.assign({}, state.cardProductMap[item.productid], item);
        return map;
    }, {});
    

    const cardProductIds = dbresult.map((item, idx)=>{
        return item.productid;
    });
    const newCardProductMap = Object.assign({}, state.cardProductMap, cardProductMap);

    let newids;
    if(action.payload.productgrpid){
        const orgids = state.cardProductIdsByGroupId[action.payload.productgrpid] || [];
        newids = uniq([...orgids, ...cardProductIds]);
        const newcardProductIdsByGroupId = Object.assign({}, state.cardProductIdsByGroupId, {[action.payload.productgrpid]: newids});
        return Object.assign({}, state, {
            cardProductIdsByGroupId: newcardProductIdsByGroupId,
            cardProductMap: newCardProductMap,
            isloading: false,
        });
    } else {
        newids = uniq([...state.cardProductIds, ...cardProductIds]);
        return Object.assign({}, state, {
            cardProductIds: newids,
            cardProductMap: newCardProductMap,
            isloading: false,
        });
    
    }   

}

function listCardProductsStarted(state, action) {
	return Object.assign({}, state, {
		isloading: true,
	});
}

function listCardProductsFailed(state, action) {
	return Object.assign({}, state, {
		isloading: false,
		error: true,
	});
}

function getProductGroupsSuccess (state, action) {
    
    const productGroups = action.payload.grpids.map((grpid, idx)=>{
        const grpname = action.payload.grpnames[idx];
        return {grpid, grpname}
    })

    return Object.assign({}, state, {
        productGroups,
        groupIsloading: false,
	});
}

function getProductGroupsStarted(state, action) {
	return Object.assign({}, state, {
		groupIsloading: true,
	});
}

function getProductGroupsFailed(state, action) {
	return Object.assign({}, state, {
		groupIsloading: false,
		groupError: true,
	});
}


function listFreeProductsSuccess (state, action) {

    const hotpdtdbresult = convetDBResultSetToArray(action.payload.hotpdtdbresult);
    const pdtdbresult = convetDBResultSetToArray(action.payload.pdtdbresult);
    

    const hotfreeProductMap = hotpdtdbresult.reduce((map, item)=>{
        map[item.productid] = Object.assign({}, state.cardProductMap[item.productid], item);
        return map;
    }, {});
    
    const freeProductMap = pdtdbresult.reduce((map, item)=>{
        map[item.productid] = Object.assign({}, state.cardProductMap[item.productid], item);
        return map;
    }, {});

    const hotfreeProductIds = hotpdtdbresult.map((item, idx)=>{
        return item.productid;
    });

    const freeProductIds = pdtdbresult.map((item, idx)=>{
        return item.productid;
    });

    const newids = uniq([...hotfreeProductIds, ...freeProductIds]);

    const newCardProductMap = Object.assign({}, state.cardProductMap, hotfreeProductMap, freeProductMap);

    return Object.assign({}, state, {
        freeProductIds: newids,
        cardProductMap: newCardProductMap,
        isloading: false,
	});
}

function listVitemProductsSuccess (state, action) {

    const hotpdtdbresult = convetDBResultSetToArray(action.payload.hotpdtdbresult);
    const pdtdbresult = convetDBResultSetToArray(action.payload.pdtdbresult);
    

    const hotvitemProductMap = hotpdtdbresult.reduce((map, item)=>{
        map[item.productid] = Object.assign({}, state.cardProductMap[item.productid], item);
        return map;
    }, {});
    
    const vitemProductMap = pdtdbresult.reduce((map, item)=>{
        map[item.productid] = Object.assign({}, state.cardProductMap[item.productid], item);
        return map;
    }, {});

    const hotvitemProductIds = hotpdtdbresult.map((item, idx)=>{
        return item.productid;
    });

    const vitemProductIds = pdtdbresult.map((item, idx)=>{
        return item.productid;
    });

    const newids = uniq([...hotvitemProductIds, ...vitemProductIds]);

    const newCardProductMap = Object.assign({}, state.cardProductMap, hotvitemProductMap, vitemProductMap);

    return Object.assign({}, state, {
        vitemProductIds: newids,
        cardProductMap: newCardProductMap,
        isloading: false,
	});
}


function listBonusProductsSuccess (state, action) {

    const hotpdtdbresult = convetDBResultSetToArray(action.payload.hotpdtdbresult);
    const pdtdbresult = convetDBResultSetToArray(action.payload.pdtdbresult);
    

    const hotbonusProductMap = hotpdtdbresult.reduce((map, item)=>{
        map[item.productid] = Object.assign({}, state.cardProductMap[item.productid], item);
        return map;
    }, {});
    
    const bonusProductMap = pdtdbresult.reduce((map, item)=>{
        map[item.productid] = Object.assign({}, state.cardProductMap[item.productid], item);
        return map;
    }, {});

    const hotbonusProductIds = hotpdtdbresult.map((item, idx)=>{
        return item.productid;
    });

    const bonusProductIds = pdtdbresult.map((item, idx)=>{
        return item.productid;
    });

    const newids = uniq([...hotbonusProductIds, ...bonusProductIds]);

    const newCardProductMap = Object.assign({}, state.cardProductMap, hotbonusProductMap, bonusProductMap);

    return Object.assign({}, state, {
        bonusProductIds: newids,
        cardProductMap: newCardProductMap,
        isloading: false,
	});
}

function getProductInfoSuccess (state, action) {
    const dbresult = convetDBResultSetToArray(action.payload.pdtinfo);
    const {canbuy, lastbuytime, hasbuycnt} = action.payload;

    const cardProductMap = dbresult.reduce((map, item)=>{
        map[item.productid] = Object.assign({}, state.cardProductMap[item.productid], item, {canbuy, lastbuytime, hasbuycnt});
        return map;
    }, {});
    const newCardProductMap = Object.assign({}, state.cardProductMap, cardProductMap);
    
    return Object.assign({}, state, {
        cardProductMap: newCardProductMap,
        isloading: false,
    });
}

function listRecommendedProductsSuccess (state, action) {

    const pdtdbresult = convetDBResultSetToArray(action.payload.result);
    
    const recommendedProductMap = pdtdbresult.reduce((map, item)=>{
        map[item.productid] = Object.assign({}, state.cardProductMap[item.productid], item);
        return map;
    }, {});

    const recommendedmProductIds = pdtdbresult.map((item, idx)=>{
        return item.productid;
    });

    const newids = uniq([...recommendedmProductIds]);

    const newCardProductMap = Object.assign({}, state.cardProductMap, recommendedProductMap);

    return Object.assign({}, state, {
        recommendedProductIds: newids,
        cardProductMap: newCardProductMap,
        isloading: false,
	});
}

function getIbonBlacklistSuccess (state, action) {
    return Object.assign({}, state, {
        ibonBlacklist: action.payload.result,
        isloading: false,
	});
}

// 操作失敗
function actionFailed (state, action) {

    if(state.error === action.payload){
        return state;
    }

    const error = action.payload;

    return Object.assign({}, state, {
        isloading: false,
        error,
	});


}


// 操作中
function actionStarted (state, action) {

    if(state.isloading){
        return state;
    }

    return Object.assign({}, state, {
        isloading: true,
	});


}


/*==========================
    Export
==========================*/
export default function createReducers (initialState) {
	return function reducer(state = initialState, action) {
        if(!actionTypes.hasOwnProperty(action.type)){
            return state;
        }
		if (reducers.hasOwnProperty(action.type)) {
            return reducers[action.type](state, action);
		} else if(action.type.lastIndexOf('Started') !== -1){
			return actionStarted(state, action);            
		} else if(action.type.lastIndexOf('Failed') !== -1){
			return actionFailed(state, action);            
        } else {
            // console.warn('Unable to find reducer for action type ', action.type);
			return state;
		}
	};
}