export default {

// 出售區 
listBuyVItemOrders: 'listBuyVItemOrders',
listBuyVItemOrdersStarted: 'listBuyVItemOrdersStarted',
listBuyVItemOrdersSuccess: 'listBuyVItemOrdersSuccess',
listBuyVItemOrdersFailed: 'listBuyVItemOrdersFailed',

getBuyVItemOrderInfo: 'getBuyVItemOrderInfo',
getBuyVItemOrderInfoStarted: 'getBuyVItemOrderInfoStarted',
getBuyVItemOrderInfoSuccess: 'getBuyVItemOrderInfoSuccess',
getBuyVItemOrderInfoFailed: 'getBuyVItemOrderInfoFailed',

replyOrderLog: 'replyOrderLog',
replyOrderLogStarted: 'replyOrderLogStarted',
replyOrderLogSuccess: 'replyOrderLogSuccess',
replyOrderLogFailed: 'replyOrderLogFailed',

addOrderLog: 'addOrderLog',
addOrderLogStarted: 'addOrderLogStarted',
addOrderLogSuccess: 'addOrderLogSuccess',
addOrderLogFailed: 'addOrderLogFailed',

listOrderLogs: 'listOrderLogs',
listOrderLogsStarted: 'listOrderLogsStarted',
listOrderLogsSuccess: 'listOrderLogsSuccess',
listOrderLogsFailed: 'listOrderLogsFailed',

addGrade: 'addGrade',
addGradeStarted: 'addGradeStarted',
addGradeSuccess: 'addGradeSuccess',
addGradeFailed: 'addGradeFailed',




// 收購區
listPurchaseOrders: 'listPurchaseOrders',
listPurchaseOrdersStarted: 'listPurchaseOrdersStarted',
listPurchaseOrdersSuccess: 'listPurchaseOrdersSuccess',
listPurchaseOrdersFailed: 'listPurchaseOrdersFailed',

getPurchaseOrderInfo: 'getPurchaseOrderInfo',
getPurchaseOrderInfoStarted: 'getPurchaseOrderInfoStarted',
getPurchaseOrderInfoSuccess: 'getPurchaseOrderInfoSuccess',
getPurchaseOrderInfoFailed: 'getPurchaseOrderInfoFailed',

updPurchaseOrderStatus: 'updPurchaseOrderStatus',
updPurchaseOrderStatusStarted: 'updPurchaseOrderStatusStarted',
updPurchaseOrderStatusSuccess: 'updPurchaseOrderStatusSuccess',
updPurchaseOrderStatusFailed: 'updPurchaseOrderStatusFailed',

replyPurchaseOrderLog: 'replyPurchaseOrderLog',
replyPurchaseOrderLogStarted: 'replyPurchaseOrderLogStarted',
replyPurchaseOrderLogSuccess: 'replyPurchaseOrderLogSuccess',
replyPurchaseOrderLogFailed: 'replyPurchaseOrderLogFailed',

addPurchaseOrderLog: 'addPurchaseOrderLog',
addPurchaseOrderLogStarted: 'addPurchaseOrderLogStarted',
addPurchaseOrderLogSuccess: 'addPurchaseOrderLogSuccess',
addPurchaseOrderLogFailed: 'addPurchaseOrderLogFailed',

getPurchaseOrderLogs: 'getPurchaseOrderLogs',
getPurchaseOrderLogsStarted: 'getPurchaseOrderLogsStarted',
getPurchaseOrderLogsSuccess: 'getPurchaseOrderLogsSuccess',
getPurchaseOrderLogsFailed: 'getPurchaseOrderLogsFailed',


};
