import demoTestActionTypes from "actions/types/demoTestActionTypes";

/*==========================
    Reducers
==========================*/
const reducers = {
	// 開啟確認Dialog
	[demoTestActionTypes.recvGetDeomTestResult]: function(state, action) {
		return recvGetDeomTestResult(state, action);
	},
};

/*==========================
    Method
==========================*/

// ------------------------------
// 開啟確認Dialog
// ------------------------------
function recvGetDeomTestResult(state, action) {

    const payload = action.payload;
    const {
        result,
    } = payload;

    const {
        newsid_matrixList,
        newsid_mixNewsMap,
    } = result;

    var ids = [];

    for(var i = 0; i < newsid_matrixList.length; i++) {
        ids.push(newsid_matrixList[i].key);
    }

    var itemMap = newsid_mixNewsMap;

    return Object.assign({}, state, {
        ids: ids,
        itemMap: itemMap,
    });
}

/*==========================
    Export
==========================*/
export default function createReducers (initialState) {
	return function reducer(state = initialState, action) {
		if (reducers.hasOwnProperty(action.type)) {
			return reducers[action.type](state, action);
		} else {
			return state;
		}
	};
}