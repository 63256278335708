/*=====================================
    Cupoy router 相關

    Author: Gray
    createtime: 2017 / 10 / 26
=====================================*/

/*--------------------------
    Import
--------------------------*/
import CupoyStateProvider from 'components/commons/router/CupoyStateProvider';

/*--------------------------
    Variables
--------------------------*/
const STATES = {
    HOME: 'home', // 首頁
    MEMBER: 'member', // 會員中心
    MEMBER_CARD_ORDER: 'member.card_order', // 會員中心 - 訂單管理 - 點卡產包
    MEMBER_CARD_ORDER_LIST_FINISH: "member.card_order.finish", // 點卡訂單 (完成交易列表)
    MEMBER_CARD_ORDER_LIST_CART: "member.card_order.cart", // 點卡訂單 (購物車列表)
    MEMBER_CARD_ORDER_UNPAY_DETAIL: 'member.card_order_list_cart_detail', // // 會員中心 - 訂單管理 - 點卡產包 - 購物車訂單 - 購物車訂單詳細列表
    MEMBER_CARD_ORDER_DETAIL_LIST: 'member.card_order_detail_list', // 會員中心 - 訂單管理 - 點卡產包 - 訂單 - 訂單詳細列表
    MEMBER_CARD_ORDER_DETAIL: 'member.card_order_detail', // 會員中心 - 訂單管理 - 點卡產包 - 訂單 - 訂單詳情
    RESET_PWD: 'reset_pwd', // 重設密碼
    MEMBER_CONTACT_SERVICE: 'member.contact_service', // 會員中心 會員客訴
    MEMBER_CONTACT_SERVICE_ADD_COMPLAINT: 'member.contact_service.add_complaint', // 會員中心 - 會員客訴 - 填寫客訴單
    MEMBER_CONTACT_SERVICE_COMPLAINT_LOGS: 'member.contact_service.complaint_logs', // 會員中心 - 會員客訴 - 客訴進度
    MEMBER_COMPLAINT: 'member.complaint', // 會員中心 - 會員客訴 - 客訴進度 - 客訴詳細頁
    MEMBER_HISTORY_RECORD: 'member.history_record', // 會員中心 - 歷程紀錄
    MEMBER_SYSTEM_MESSAGE: 'member.system_message', // 會員中心 - 系統訊息
    MEMBER_INVOICE: 'member.invoice', // 會員中心 - 發票管理
    MEMBER_REFUND: 'member.refund', // 會員中心 - 帳戶退款
    MEMBER_REFUND_APPLY: 'member.refund.apply', // 會員中心 - 帳戶退款 - 我要退款
    MEMBER_REFUND_LOGS: 'member.refund.logs', // 會員中心 - 帳戶退款 - 退款申請紀錄
    MEMBER_BONUS: 'member.bonus', // 會員中心 - 發財金
    MEMBER_BONUS_LOGS: 'member.bonus.logs', // 會員中心 - 發財金- 發財金明細
    MEMBER_BONUS_ORDER: 'member.bonus.order', // 會員中心 - 發財金- 兌換紀錄
    MEMBER_BONUS_ORDER_DETAIL: 'member.bonus_order_detail', // 會員中心 - 發財金 - 兌換紀錄詳情
    MEMBER_BONUS_DEPOSIT: 'member.bonus.deposit', // 會員中心 - 發財金- 兌換發財金
    MEMBER_INFO: 'member.info', // 會員中心 修改個人資訊
    MEMBER_VITEM_ORDER: 'member.vitem_order', // 會員中心 - 虛寶市集交易
    MEMBER_VITEM_ORDER_SALE: 'member.vitem_order.sale', // 會員中心 - 虛寶市集交易 - 出售區
    MEMBER_VITEM_ORDER_PURCHASE: 'member.vitem_order.purchase', // 會員中心 - 虛寶市集 - 收購區
    MEMBER_VITEM_ORDER_DETAIL_SALE: 'member.vitem_order_detail_sale', // 會員中心 - 虛寶市集 - 出售區訂單詳情
    MEMBER_VITEM_ORDER_DETAIL_PURCHASE: 'member.vitem_order_detail_purchase', // 會員中心 - 虛寶市集 - 收購區訂單詳情
    MEMBER_CART: 'member.cart', // 會員中心 - 購物車
    MEMBER_CHECKOUT: 'member.checkout', // 會員中心 - 結帳
    MEMBER_CART_PAYMENT_INFO: 'member.cart_payment_info', // 會員中心 - 結帳付款資訊
    MEMBER_ESUN_WEB_ATM_RESULT: 'member.esun_web_atm_result', // 玉山WebATM付款結果頁
    REGISTER: 'register', // 註冊頁面
    NEWS: 'news', // 最新消息
    NEWS_CONTENT: 'news.content', // 最新消息 詳細頁面
    PROFITEVENT: 'profitevent', // 好康活動
    GAME: 'game', // 虛寶市集首頁 
    GAME_TEMP_TRANSACTION: 'game.temp_transaction', // 虛寶市集
    GAME_TRANSACTION: 'game.transaction', // 虛寶市集
    GAME_TRANSACTION_PURCHASE: 'game.transaction.purchase', // 虛寶市集 收購區
    GAME_TRANSACTION_SALE: 'game.transaction.sale', // 虛寶市集 出售區
    GAME_SALE_DETAIL: 'game_sale_detail', // 虛寶市集 出售區詳情
    GAME_PURCHASE_DETAIL: 'game_purchase_detail', // 虛寶市集 收購區詳情
    BONUSPRODUCTS: 'bonusproducts', // 發財商城
    BONUSPRODUCTS_USE: 'bonusproducts.use', // 發財商城 使用說明
    BONUSPRODUCTS_RECORD: 'bonusproducts.record', // 發財商城 發財金紀錄
    BONUSPRODUCTS_CONVERT: 'bonusproducts.convert', // 發財商城 發財金兌換
    FREEPRODUCTS: 'freeproducts', // 免費索取
    VITEMPRODUCTS: 'vitemproducts', // 虛寶包
    POINTCARD: 'pointcard', // 點卡產包
    POINTCARD_PRODUCT: 'pointcard_product', // 點卡產品詳細頁
    VITEM_PRODUCT: 'vitem_product', // 虛寶包產品詳細頁
    FREE_PRODUCT: 'free_product', // 免費索取產品詳細頁
    BONUS_PRODUCT: 'bonus_product', // 發財商城產品詳細頁
    HELP: 'help', // 幫助中心
    HELP_UPGRADE_MEMBERSHIP: 'help.upgrade_membership', // 幫助中心 - 如何升級會員
    // HELP_VISITOR_BOOK: 'help.visitor_book', // 幫助中心 - 訪客留言
    HELP_MEMBER_SUSPENSION_OF_POWER: 'help.member_suspension_of_power', // 幫助中心 - 會員停權
    HELP_TRANSACTION_SECURITY: 'help.transaction_security', // 幫助中心 - 交易安全
    HELP_TRANSACTION_ORDER: 'help.transaction_order', // 幫助中心 繳費說明
    HELP_BONUS_BASE_INTRO: 'help.bonus_base_intro', // 幫助中心 何謂發財金
    HELP_BONUS_CALC_INTRO: 'help.bonus_calc_intro', // 幫助中心 如何計算發財金
    HELP_BONUS_ELIGIBILITY: 'help.bonus_eligibility', // 幫助中心 發財金參加資格
    HELP_BONUS_MANUAL: 'help.bonus_manual', // 幫助中心 如何使用發財金
    PRODUCT_CHECKOUT: 'product_checkout',
    MAIL_IDENTIFY: 'mail_identify', // 驗證Email
    CHANGE_MAIL_IDENTIFY: 'change_mail_identify', // 修改mail的驗證
    HELP_ABOUT: 'help.about', // 關於淞果
    HELP_PRIVACY_POLICY: 'help.privacy_policy', // 隱私權聲明
    DISCLAIMER: 'help.disclaimer', // 免責聲明
    MEMBER_TERMS: 'help.member_terms', // 會員條款
    NEW_PRODUCTS: 'new_products', // 新品上市
    WEBATM_PAYER: 'webatm_payer', // call webatm api route
    MEMBER_CARRIER_PLATFORM: 'member_carrier_platform', // 大平台歸戶
    THIRD_PARTY_RESULT: 'third_party_result', // 第三方服務溝通結果頁面
};

var $stateProvider = new CupoyStateProvider();

$stateProvider.otherwise('/');

$stateProvider
    .state(STATES.HOME, '/')
    .state(STATES.RESET_PWD, '/resetpwd/:token/:mrscid')
    .state(STATES.MEMBER, '/member')
        .state(STATES.MEMBER_CARD_ORDER, '/card_order')
        .state(STATES.MEMBER_CARD_ORDER_LIST_FINISH, '/finish')    
        .state(STATES.MEMBER_CARD_ORDER_LIST_CART, '/cart')
        .state(STATES.MEMBER_CARD_ORDER_UNPAY_DETAIL, '/card_order_unpay_detail/:cartid')
        .state(STATES.MEMBER_CARD_ORDER_DETAIL_LIST, '/card_order_list/:id')
        .state(STATES.MEMBER_CARD_ORDER_DETAIL, '/card_order_list/:id/:jcardid')
        .state(STATES.MEMBER_CONTACT_SERVICE, '/contact_service')
            .state(STATES.MEMBER_CONTACT_SERVICE_ADD_COMPLAINT, '/add_complaint')
            .state(STATES.MEMBER_CONTACT_SERVICE_COMPLAINT_LOGS, '/complaint_logs')
        .state(STATES.MEMBER_COMPLAINT, '/complaint/:id/:createtime')
        .state(STATES.MEMBER_HISTORY_RECORD, '/history_record')
        .state(STATES.MEMBER_SYSTEM_MESSAGE, '/system_message')
        .state(STATES.MEMBER_INVOICE, '/invoice')
        .state(STATES.MEMBER_REFUND, '/refund')
            .state(STATES.MEMBER_REFUND_APPLY, '/apply')
            .state(STATES.MEMBER_REFUND_LOGS, '/logs')
        .state(STATES.MEMBER_BONUS, '/bonus')
            .state(STATES.MEMBER_BONUS_LOGS, '/logs')
            .state(STATES.MEMBER_BONUS_ORDER, '/order')
            .state(STATES.MEMBER_BONUS_DEPOSIT, '/deposit')
        .state(STATES.MEMBER_BONUS_ORDER_DETAIL, '/bonus_order/:id')
        .state(STATES.MEMBER_INFO, '/info')
        .state(STATES.MEMBER_VITEM_ORDER, '/vitem_order')
            .state(STATES.MEMBER_VITEM_ORDER_SALE, '/sale')
            .state(STATES.MEMBER_VITEM_ORDER_PURCHASE, '/purchase')
        .state(STATES.MEMBER_VITEM_ORDER_DETAIL_SALE, '/vitem_order_detail/sale/:id')
        .state(STATES.MEMBER_VITEM_ORDER_DETAIL_PURCHASE, '/vitem_order_detail/purchase/:id')
        .state(STATES.MEMBER_CART, '/cart')
        .state(STATES.MEMBER_CHECKOUT, '/checkout')
        .state(STATES.MEMBER_CART_PAYMENT_INFO, '/cart_payment_info/:id')
        .state(STATES.MEMBER_ESUN_WEB_ATM_RESULT, '/esun-web-atm-result/:status/:msg')
    .state(STATES.REGISTER, '/member/register')
    .state(STATES.NEWS, '/news')
        .state(STATES.NEWS_CONTENT, '/content/:id')
    .state(STATES.PROFITEVENT, '/profitevent')
    .state(STATES.GAME, '/game')
        .state(STATES.GAME_TEMP_TRANSACTION, '/transaction/:gameid')
        .state(STATES.GAME_TRANSACTION, '/transaction/:gameid/:serverid')
            .state(STATES.GAME_TRANSACTION_SALE, '/sale')
            .state(STATES.GAME_TRANSACTION_PURCHASE, '/purchase')
    .state(STATES.GAME_SALE_DETAIL, '/sale_detail/:gameid/:serverid/:viprovid')
    .state(STATES.GAME_PURCHASE_DETAIL, '/purchase_detail/:gameid/:gameserverid/:vprovmrscid/:purchaseid')
    .state(STATES.BONUSPRODUCTS, '/bonusproducts')
        .state(STATES.BONUSPRODUCTS_USE, '/use')
        .state(STATES.BONUSPRODUCTS_RECORD, '/record')
        .state(STATES.BONUSPRODUCTS_CONVERT, '/convert')
    .state(STATES.FREEPRODUCTS, '/freeprocucts')
    .state(STATES.VITEMPRODUCTS, '/vitemproducts')
    .state(STATES.POINTCARD, '/pointcard')
    .state(STATES.POINTCARD_PRODUCT, '/pointcard_product/:id')
    .state(STATES.VITEM_PRODUCT, '/vitem_product/:id')
    .state(STATES.FREE_PRODUCT, '/free_product/:id')
    .state(STATES.BONUS_PRODUCT, '/bonus_product/:id')
    .state(STATES.HELP, '/help')
        .state(STATES.HELP_UPGRADE_MEMBERSHIP, '/upgrade_membership')
        // .state(STATES.HELP_VISITOR_BOOK, '/visitor_book')
        .state(STATES.HELP_MEMBER_SUSPENSION_OF_POWER, '/member_suspension_of_power')
        .state(STATES.HELP_TRANSACTION_SECURITY, '/transaction_security')
        .state(STATES.HELP_TRANSACTION_ORDER, '/transaction_order')
        .state(STATES.HELP_BONUS_BASE_INTRO, '/bonus_base_intro')
        .state(STATES.HELP_BONUS_CALC_INTRO, '/bonus_calc_intro')
        .state(STATES.HELP_BONUS_ELIGIBILITY, '/bonus_eligibility')
        .state(STATES.HELP_BONUS_MANUAL, '/bonus_manual')
        .state(STATES.HELP_ABOUT, '/about')
        .state(STATES.HELP_PRIVACY_POLICY, '/privacy_policy')
        .state(STATES.DISCLAIMER, '/disclaimer')
        .state(STATES.MEMBER_TERMS, '/member_terms')
    .state(STATES.PRODUCT_CHECKOUT, '/product_checkout')
    .state(STATES.MAIL_IDENTIFY, '/mail-identify/:emailauth/:mrscid')
    .state(STATES.CHANGE_MAIL_IDENTIFY, '/change-mail-identify/:emailauth/:mrscid')
    .state(STATES.NEW_PRODUCTS, '/new_products')
    .state(STATES.WEBATM_PAYER, '/webatm-payer')
    .state(STATES.MEMBER_CARRIER_PLATFORM, '/member-carrier-platform/:token')
    .state(STATES.THIRD_PARTY_RESULT, '/third-party-result/:type')
;

var PATHS = {};

for(var key in STATES) {

    var statename = STATES[key];

    PATHS[key] = $stateProvider.absolutePathMap[statename];
}

/*--------------------------
    Method
--------------------------*/

// ------------------------------
// 取得 react router 所需要的陣列
// ------------------------------
function getReactRouterList() {

    return $stateProvider.getReactRouterList();
}

// ------------------------------
// 更新目前state
// ------------------------------
function updateCurrent(location, action) {

    return $stateProvider.updateCurrent(location, action);
}

// ------------------------------
// 更新目前state
// ------------------------------
function updateCurrentByPath(path, action) {

    return $stateProvider.updateCurrentByPath(path, action);
}

// ------------------------------
// 更新目前state
// ------------------------------
function updateCurrentByState(statename, params, querys, path, action) {

    return $stateProvider.updateCurrentByState(statename, params, querys, path, action);
}

// ------------------------------
// 取得目前state
// ------------------------------
function getCurrent() {

    return $stateProvider.current;
}

// ------------------------------
// 取得state實際網址（含querys）
// ------------------------------
function getUrl(statename, params, querys) {

    return $stateProvider.getUrl(statename, params, querys);
}

// ------------------------------
// 取得state實際網址（無querys）
// ------------------------------
function getPathname(statename, params) {

    return $stateProvider.getPathname(statename, params);
}

// ------------------------------
// 取得query網址
// ------------------------------
function getQueryUrl(querys) {

    return $stateProvider.getQueryUrl(querys);
}

// ------------------------------
// 傳入的statename是否為目前state的state或是子state
// ------------------------------
function includes(statename) {

    return $stateProvider.includes(statename);
}

// ------------------------------
// 是否有該state
// ------------------------------
function hasState(statename) {

    return $stateProvider.hasState(statename);
}

// ------------------------------
// 判斷是否在此頁
// ------------------------------
function isActive(statename, params, querys) {

    return $stateProvider.isActive(statename, params, querys);
}

// ------------------------------
// 判斷目前是否為未定義的頁面
// ------------------------------
function isUnknowState() {

    return $stateProvider.isUnknowState();
}

// ------------------------------
// 取得otherwise
// ------------------------------
function getOtherwise() {

    return $stateProvider.otherwisePath;
}

// ------------------------------
// 取得歷史記錄
// ------------------------------
function getHistory() {

    return $stateProvider.getHistory();
}

const CupoyRouter = {
    /*--------------------------
        Variables
    --------------------------*/
    STATES, // Cupoy 所有state
    PATHS, // State 對應到的 path

    /*--------------------------
        Method
    --------------------------*/
    getReactRouterList, // 取得 react router 所需要的陣列
    updateCurrent, // 更新目前state
    updateCurrentByPath, // 更新目前state
    updateCurrentByState, // 更新目前state
    getCurrent, // 取得目前state
    getUrl, // 取得state實際網址（含querys）
    getPathname, // 取得state實際網址（無querys）
    getQueryUrl, // 取得query網址
    includes, // 傳入的statename是否為目前state的state或是子state
    hasState, // 是否有該state
    isActive, // 判斷是否在此頁
    isUnknowState, // 判斷目前是否為未定義的頁面
    getOtherwise, // 取得otherwise
    getHistory, // 取得歷史記錄
};

export default CupoyRouter;
