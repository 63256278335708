import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
// import ReactDOM from 'react-dom';
import Trigger from 'rc-trigger';
import 'rc-trigger/assets/index.css';

// import classNames from "classnames";

export default class Dropdown extends PureComponent {
  static propTypes = {
    minOverlayWidthMatchTrigger: PropTypes.bool,
    onVisibleChange: PropTypes.func, // dropdown show/hide callback
    prefixCls: PropTypes.string, // 用來換掉整個trigger的前置class字串
    children: PropTypes.any, // 包覆元件
    transitionName: PropTypes.string, // 動畫效果名稱
    overlayClassName: PropTypes.string, // 額外要給dropdown畫面的class
    animation: PropTypes.any, // 動畫效果object 
    align: PropTypes.object, // 同placement，但可以直接設定align物件
    overlayStyle: PropTypes.object, // 額外要給dropdown畫面的style
    placement: PropTypes.string, // 展現位置，參考下面的placements
    overlay: PropTypes.node, // 要展現的dropdown
    trigger: PropTypes.array, // 如何觸發dropdown ['click', 'focus', 'hover']
    showAction: PropTypes.array, // 可以將show/hide的trigger分別設定
    hideAction: PropTypes.array, // 可以將show/hide的trigger分別設定
    getPopupContainer: PropTypes.func,
    visible: PropTypes.bool, // 用這個強制開關dropdown
    defaultVisible: PropTypes.bool,
    destroyPopupOnHide: PropTypes.bool, // 點擊其他地方後是否關閉dropdown
    afterVisibleChange: PropTypes.func,
  };

  static defaultProps = {
    minOverlayWidthMatchTrigger: true,
    prefixCls: 'rc-trigger-popup',
    trigger: ['click'],
    showAction: [],
    hideAction: [],
    overlayClassName: '',
    overlayStyle: {},
    defaultVisible: false,
    onVisibleChange() {},
    placement: 'bottomLeft',
    destroyPopupOnHide: true,
  }

  constructor(props) {
    super(props);

    if ('visible' in props) {
      this.state = {
        visible: props.visible,
      };
    } else {
      this.state = {
        visible: props.defaultVisible,
      };
    }
  }

  componentWillReceiveProps({ visible }) {
    if (visible !== undefined) {
      this.setState({
        visible,
      });
    }
  }

  onClick = (e) => {
    const props = this.props;
    const overlayProps = props.overlay.props;
    // do no call onVisibleChange, if you need click to hide, use onClick and control visible
    if (!('visible' in props)) {
      // 點擊Dropdown內部任何地方的時候不要自動關閉Dropdown
      // this.setState({
      //   visible: false,
      // });
    }
    if (overlayProps.onClick) {
      overlayProps.onClick(e);
    }
  }

  hide = () => {
    this.setState({
      visible: false
    });
  }

  onVisibleChange = (visible) => {
    const props = this.props;
    if (!('visible' in props)) {
      this.setState({
        visible,
      });
    }

    props.onVisibleChange(visible);
    // if (visible) {
    //   document.addEventListener('scroll', this.scrollHandler);
    // } else {
    //   document.removeEventListener('scroll', this.scrollHandler);
    // }
  }

  // scrollHandler = () => {
    
  //   this.hide();
  // }

  getMenuElement() {
    const { overlay, prefixCls } = this.props;
    const extraOverlayProps = {
      prefixCls: `${prefixCls}-menu`,
      onClick: this.onClick,
    };
    if (typeof overlay.type === 'string') {
      delete extraOverlayProps.prefixCls;
    }
    return React.cloneElement(overlay, extraOverlayProps);
  }

  // getPopupDomNode() {
  //   return this.refs.trigger.getPopupDomNode();
  // }

  afterVisibleChange = (visible) => {
    this.props.afterVisibleChange && this.props.afterVisibleChange(visible, this.popPlacement);
  }

  onPopupAlign = (popupDomNode, align) => {

    if(!align || !Array.isArray(align.points) || align.points.length !== 2) {
      return;
    }

    var placement, popPlacement;

    for(var key in placements) {
      placement = placements[key];

      if(align.points[0] === placement.points[0]
        && align.points[1] === placement.points[1]
      ) {
        popPlacement = key;
      }
    }
    this.popPlacement = popPlacement;
  }

  render() {
    const {
      prefixCls, children,
      transitionName, animation,
      align, placement, getPopupContainer,
      showAction, hideAction,
      overlayClassName, overlayStyle,
      trigger, destroyPopupOnHide, ...otherProps
    } = this.props;

    return (
      <Trigger
        {...otherProps}
        prefixCls={prefixCls}
        ref="trigger"
        popupClassName={overlayClassName}
        popupStyle={overlayStyle}
        builtinPlacements={placements}
        destroyPopupOnHide={destroyPopupOnHide}
        action={trigger}
        showAction={showAction}
        hideAction={hideAction}
        popupPlacement={placement}
        popupAlign={align}
        popupTransitionName={transitionName}
        popupAnimation={animation}
        popupVisible={this.state.visible}
        afterPopupVisibleChange={this.afterVisibleChange}
        popup={this.getMenuElement()}
        onPopupVisibleChange={this.onVisibleChange}
        // getPopupContainer={()=> document.getElementById("dropdownContainer")}
        getPopupContainer={getPopupContainer}
        onPopupAlign={this.onPopupAlign}
      >
        {children}
      </Trigger>
    );
  }
}

const autoAdjustOverflow = {
  adjustX: 1,
  adjustY: 1,
};

const targetOffset = [0, 0];

const placements = {
  leftTop: {
    points: ['cr', 'tl'],
    overflow: autoAdjustOverflow,
    offset: [-4, 0],
    targetOffset,
  },
  leftCenter: {
    points: ['cr', 'cl'],
    overflow: autoAdjustOverflow,
    offset: [-4, 0],
    targetOffset,
  },
  leftBottom: {
    points: ['cr', 'bl'],
    overflow: autoAdjustOverflow,
    offset: [-4, 0],
    targetOffset,
  },
  rightTop: {
    points: ['tl', 'tr'],
    overflow: autoAdjustOverflow,
    offset: [4, 0],
    targetOffset,
  },
  rightCenter: {
    points: ['cl', 'cr'],
    overflow: autoAdjustOverflow,
    offset: [4, 0],
    targetOffset,
  },
  rightBottom: {
    points: ['cl', 'br'],
    overflow: autoAdjustOverflow,
    offset: [4, 0],
    targetOffset,
  },
  topLeft: {
    points: ['bl', 'tl'],
    overflow: autoAdjustOverflow,
    offset: [0, -4],
    targetOffset,
  },
  topCenter: {
    points: ['bc', 'tc'],
    overflow: autoAdjustOverflow,
    offset: [0, -4],
    targetOffset,
  },
  topRight: {
    points: ['br', 'tr'],
    overflow: autoAdjustOverflow,
    offset: [0, -4],
    targetOffset,
  },
  bottomLeft: {
    points: ['tl', 'bl'],
    overflow: autoAdjustOverflow,
    offset: [0, 4],
    targetOffset,
  },
  bottomCenter: {
    points: ['tc', 'bc'],
    overflow: autoAdjustOverflow,
    offset: [0, 4],
    targetOffset,
  },
  bottomRight: {
    points: ['tr', 'br'],
    overflow: autoAdjustOverflow,
    offset: [0, 4],
    targetOffset,
  },
};

