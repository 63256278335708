// import actionTypes from 'actions/types/systemMsgActionTypes';


/*==========================
    Reducers
==========================*/
const reducers = {};

/*==========================
    Export
==========================*/
export default function createReducers(initialState) {
	return function reducer(state = initialState, action) {
		if(reducers.hasOwnProperty(action.type)) {
			return reducers[action.type](state, action);
		} else {
			return state;
		}
	};
}