export default {
registerMember: 'registerMember',
registerMemberStarted: 'registerMemberStarted',
registerMemberSuccess: 'registerMemberSuccess',
registerMemberFailed: 'registerMemberFailed',

checkPhone: 'checkPhone',
checkPhoneStarted: 'checkPhoneStarted',
checkPhoneSuccess: 'checkPhoneSuccess',
checkPhoneFailed: 'checkPhoneFailed',

checkEmail: 'checkEmail',
checkEmailStarted: 'checkEmailStarted',
checkEmailSuccess: 'checkEmailSuccess',
checkEmailFailed: 'checkEmailFailed',

sendSmsAuthCode: 'sendSmsAuthCode',
sendSmsAuthCodeStarted: 'sendSmsAuthCodeStarted',
sendSmsAuthCodeSuccess: 'sendSmsAuthCodeSuccess',
sendSmsAuthCodeFailed: 'sendSmsAuthCodeFailed',

verifyRegisterSms: 'verifyRegisterSms',
verifyRegisterSmsStarted: 'verifyRegisterSmsStarted',
verifyRegisterSmsSuccess: 'verifyRegisterSmsSuccess',
verifyRegisterSmsFailed: 'verifyRegisterSmsFailed',

verifyRegisterEmail: 'verifyRegisterEmail',
verifyRegisterEmailStarted: 'verifyRegisterEmailStarted',
verifyRegisterEmailSuccess: 'verifyRegisterEmailSuccess',
verifyRegisterEmailFailed: 'verifyRegisterEmailFailed',


};
