/*=====================================
    FooterView

    Author: Ac
    CreateTime: 2018 / 08 / 16
=====================================*/

/*--------------------------
    Import
--------------------------*/
import React, { Component } from 'react';
// import PropTypes from 'prop-types';
import styled from 'styled-components';
import { GlobalStyle, Colors } from 'stylecomponents';
import CupoyRouter from 'components/commons/router/CupoyRouter';
import CupoySrefView from 'components/commons/router/CupoySrefView';
import CupoyImage from 'components/commons/CupoyImage';
import GLOBAL from 'nonnglobal';

/**
|--------------------------------------------------
| Variables
|--------------------------------------------------
*/
const NAVS = [
    {
        title: '商品資訊',
        type: 'link',
        list: [
            {
                name: '虛寶市集',
                routerState: CupoyRouter.STATES.GAME,
            },
            {
                name: '點數卡',
                routerState: CupoyRouter.STATES.POINTCARD,
            },
            {
                name: '虛寶包',
                routerState: CupoyRouter.STATES.VITEMPRODUCTS,
            },
            {
                name: '免費索取',
                routerState: CupoyRouter.STATES.FREEPRODUCTS,
            },
            {
                name: '發財商城',
                routerState: CupoyRouter.STATES.BONUSPRODUCTS,
            },
        ],
    },
    {
        title: '幫助中心',
        type: 'link',
        list: [
            {
                name: '新手上路',
                routerState: CupoyRouter.STATES.HELP_UPGRADE_MEMBERSHIP,
            },
            {
                name: '交易須知',
                routerState: CupoyRouter.STATES.HELP_TRANSACTION_SECURITY,
            },
            {
                name: '發財金',
                routerState: CupoyRouter.STATES.HELP_BONUS_BASE_INTRO,
            },
            {
                name: '關於淞果',
                routerState: CupoyRouter.STATES.HELP_ABOUT,
            },
        ],
    },
    {
        title: '聲明',
        type: 'link',
        list: [
            {
                name: '會員條款',
                routerState: CupoyRouter.STATES.MEMBER_TERMS,
            },
            {
                name: '免責聲明',
                routerState: CupoyRouter.STATES.DISCLAIMER,
            },
            {
                name: '隱私權聲明',
                routerState: CupoyRouter.STATES.HELP_PRIVACY_POLICY,
            },
        ],
    },
    {
        title: '繳費方式',
        type: 'link',
        list: [
            {
                name: '信用卡',
                routerState: CupoyRouter.STATES.HELP_TRANSACTION_ORDER,
                querys: {
                    paymentType: GLOBAL.PAYTYPE.ALLPAY_CREDITCARD,
                },
            },
            {
                name: 'WEB ATM',
                routerState: CupoyRouter.STATES.HELP_TRANSACTION_ORDER,
                querys: {
                    paymentType: GLOBAL.PAYTYPE.ECPAYATM,
                },
            },
            {
                name: '全家',
                routerState: CupoyRouter.STATES.HELP_TRANSACTION_ORDER,
                querys: {
                    paymentType: GLOBAL.PAYTYPE.FAMIPORT,
                },
            },
            {
                name: '7-11',
                routerState: CupoyRouter.STATES.HELP_TRANSACTION_ORDER,
                querys: {
                    paymentType: GLOBAL.PAYTYPE.IBON,
                },
            },
            {
                name: '萊爾富',
                routerState: CupoyRouter.STATES.HELP_TRANSACTION_ORDER,
                querys: {
                    paymentType: GLOBAL.PAYTYPE.HILIFE,
                },
            },
        ],
    },
];
/*--------------------------
    Styled
--------------------------*/
const CopyRight = styled.div`
    padding: 10px 0;
    background-color: ${Colors.MAIN_COLOR};
    text-align: center;
    color: #FFF;
    font-size: 12px;
    line-height: 18px;
`;
const Footer = styled.footer`
    background-color: #FFF;
    margin-top: 50px;
    box-shadow: inset 0 1px 0 0 #DDDDDD;
`;
const Content = styled.div`
    display: flex;
    flex-direction: row;
    width: ${GlobalStyle.ContainerWidth};
    width: ${GlobalStyle.ContainerMaxWidth}px;
    margin: 0 auto;
    height: 317px;
`;
const LogoWrapper = styled.div`
    width: 208px;
    height: 96px;
    margin: 0 auto;
`;
const Logo = styled.div`
    width: 208px;
    height: 96px;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    cursor: pointer;
`;
const WebsiteInfoWrapper = styled.div`
    margin-top: 40px;
    width: 232px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-right: 80px;
    align-content: flex-start;
`;
const EInvoice = styled.div`
    width: 84px;
    height: 29px;
    margin: 0 auto 5px;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
`;
const ProtectionClass = styled.div`
    width: 29px;
    height: 26px;
    margin: 0 auto 9px;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
`;
const WebsiteInfo = styled.div`
    margin-top: 16px;
    text-align: center;
    width: 108px;
    font-size: 12px;
    color: #4A4A4A;
`;
const Nav = styled.ul`
    display: flex;
    flex-direction: row;
    margin: 40px 0 0;
`;
const NavItem = styled.li`
    margin-right: 115px;

    &:last-child {
        margin-right: 0;
    }
`;
const NavItemList = styled.ul`
    margin: 0;
`;
const NavItemListItem = styled.li`
    font-size: 14px;
    color: #4A4A4A;
    font-weight: ${({ isTitle }) => isTitle ? '600' : 'normal'};
    margin-bottom: ${({ isTitle }) => isTitle ? '10px' : '8px'};
    cursor: ${({ notLink }) => notLink ? 'default' : 'pointer'};
`;
const Contact = styled.div`
    color: #4A4A4A;
    font-size: 14px;
`;
const ContactTitle = styled.div`
    font-weight: 600;
    margin-bottom: 10px;
`;
const ContactItem = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 8px;

    > a {
        text-decoration: underline;
        color: inherit;

        &:hover {
            text-decoration: underline;
        }
    }

    &:last-child {
        margin-bottom: 16px;
    }
`;
const SocialLink = styled.a`
    display: block;
    margin-right: 6px;
`;
const SocialImg = styled.div`
    width: 35px;
    min-width: 35px;
    height: 35px;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    cursor: pointer;
`;
const ContactBtn = styled.div`
    width: 84px;
    height: 29px;
    color: #FFF !important;
    background-color: ${Colors.MAIN_COLOR};
    text-decoration: none !important;
    line-height: 29px;
    text-align: center;
    border-radius: 2px;
    color: #FFF;
    font-size: 12px;
    font-weight: 600;
    cursor: pointer;

    &:hover {
        text-decoration: none !important;
    }

    &:first-child {
        margin-right: 10px;
    }
`;

/*--------------------------
    Main Component
--------------------------*/
class FooterView extends Component {
    
    render() {

        return (
            <Footer>
                <Content>
                    <WebsiteInfoWrapper>
                        <CupoySrefView
                            statename={CupoyRouter.STATES.HOME}
                            stylecomponent={LogoWrapper}
                        >
                            <CupoyImage 
                                src={'images/logo.png'}
                                stylecomponent={Logo}
                                isBackgroundImage
                            />
                        </CupoySrefView>
                        <a href="https://www.einvoice.nat.gov.tw/" target='_blank' rel='noopener noreferrer'>
                            <WebsiteInfo>
                                <CupoyImage 
                                    src={'images/e_invoice.jpg'}
                                    stylecomponent={EInvoice}
                                    isBackgroundImage
                                />
                                本網站採用電子發票
                            </WebsiteInfo>
                        </a>
                        <a href="http://www.gamerating.org.tw/" target='_blank' rel='noopener noreferrer'>
                            <WebsiteInfo>
                                <CupoyImage 
                                    src={'images/protection_class.jpg'}
                                    stylecomponent={ProtectionClass}
                                    isBackgroundImage
                                />
                                本網站內容為保護級
                            </WebsiteInfo>
                        </a>
                    </WebsiteInfoWrapper>
                    <Nav>
                        {
                            NAVS.map(nav => {

                                let {
                                    title,
                                    type,
                                    list,
                                } = nav;

                                return (
                                    <NavItem key={title}>
                                        <NavItemList>
                                            <NavItemListItem isTitle notLink>
                                                {title}
                                            </NavItemListItem>
                                            {
                                                list.map(({ name, routerState, querys }) => {

                                                    const isLink = type === 'link';

                                                    return (
                                                        isLink && routerState ?
                                                        <CupoySrefView
                                                            key={name}
                                                            statename={routerState}
                                                            querys={querys}
                                                        >
                                                            <NavItemListItem>
                                                                {name}
                                                            </NavItemListItem>
                                                        </CupoySrefView>
                                                        :
                                                        <NavItemListItem
                                                            key={name}
                                                            notLink
                                                        >
                                                            {name}
                                                        </NavItemListItem>
                                                    );
                                                })
                                            }
                                        </NavItemList>
                                    </NavItem>
                                );
                            })
                        }
                        <Contact>
                            <ContactTitle>聯絡我們</ContactTitle>
                            <ContactItem>週一至週五 10:00-19:00</ContactItem>
                            <ContactItem>週六至週日 14:00-22:00</ContactItem>
                            <ContactItem>客服專線：(02) 7731-2321</ContactItem>
                            <ContactItem>傳真電話：(02) 8227-8089</ContactItem>
                            <ContactItem>
                                客服信箱：
                                <a
                                    target='_blank'
                                    rel='noopener noreferrer'
                                    href='mailto:service@9199.com.tw'
                                >
                                    service@9199.com.tw
                                    </a>
                            </ContactItem>
                            <ContactItem>
                                聯絡我們：
                                <SocialLink
                                    target='_blank'
                                    rel='noopener noreferrer'
                                    href='https://www.facebook.com/9199fan/'
                                >
                                    <CupoyImage 
                                        src={'images/fb.png'}
                                        stylecomponent={SocialImg}
                                        isBackgroundImage
                                    />
                                </SocialLink>
                                <SocialLink
                                    target='_blank'
                                    rel='noopener noreferrer'
                                    href='https://bit.ly/2werpZ5'
                                >
                                    <CupoyImage 
                                        src={'images/telegram.png'}
                                        stylecomponent={SocialImg}
                                        isBackgroundImage
                                    />
                                </SocialLink>
                                <SocialLink
                                    target='_blank'
                                    rel='noopener noreferrer'
                                    href='mailto:service@9199.com.tw'
                                >
                                    <CupoyImage 
                                        src={'images/mail.png'}
                                        stylecomponent={SocialImg}
                                        isBackgroundImage
                                    />
                                </SocialLink>
                                <SocialLink
                                    target='_blank'
                                    rel='noopener noreferrer'
                                    href='https://www.youtube.com/@9199online'
                                >
                                    <CupoyImage 
                                        src={'images/youtube.png'}
                                        stylecomponent={SocialImg}
                                        isBackgroundImage
                                    />
                                </SocialLink>
                            </ContactItem>
                            <ContactItem>
                                <CupoySrefView
                                    statename={CupoyRouter.STATES.MEMBER_CONTACT_SERVICE_ADD_COMPLAINT}
                                    stylecomponent={ContactBtn}
                                >
                                    會員客訴
                                </CupoySrefView>
                                {/* <CupoySrefView
                                    statename={CupoyRouter.STATES.HELP_VISITOR_BOOK}
                                    stylecomponent={ContactBtn}
                                >
                                    訪客留言
                                </CupoySrefView> */}
                            </ContactItem>
                        </Contact>
                    </Nav>
                </Content>
                <CopyRight>
                    本站相關圖文版權均屬該原廠官方所有   淞果數位股份有限公司 Copyright &copy; 2009~{new Date().getFullYear()} SoCool Digital CO., LTD. ALL Rights Resverved.<br/>
                    提醒您不得利用遊戲從事違反法令行為，長時間連續進行遊戲可能影響身心健康!
                </CopyRight>
            </Footer>
        );
    }

}
/*--------------------------
    Export
--------------------------*/
export default FooterView;