import actionTypes from "actions/types/idxPageActionTypes";
import GLOBAL from 'nonnglobal';
import customAxios from 'api/customAxios';

const IDX_PAGE_JSON_CACHE_URL = `${GLOBAL.imgSrcRootURL}/idxpagecache.json`;
const IDX_PAGE_API_URL = '/IdxPageInfoAction.do';
const TOP_AD_LIST_API_URL = '/AdAction.do?op=listADs&start=0&layoutid=0&len=12';

// begin check member login sync action creator
function getIdxPageInfoStarted(payload) {
    return { type: actionTypes.getIdxPageInfoStarted, payload };
}


// begin check member login sync action creator
function setIdxPageInfo(payload) {
    return { type: actionTypes.setIdxPageInfo, payload };
}


// begin check member login sync action creator
function getIdxPageInfoFailed(payload) {
    return { type: actionTypes.getIdxPageInfoFailed, payload };
}



// check member login thunk
function getIdxPageInfo(payload = {}) {
    return dispatch => {

        dispatch(getIdxPageInfoStarted(payload)); // dispatch synced action to update loading status

        const promise = new Promise((resolve, reject) => {

            customAxios(`${IDX_PAGE_JSON_CACHE_URL}?t=${Date.now()}`)
                .then(response => {
                    
                    if (response.status === 503) {
                        // server正常，apache也存活，但是resin已經被關閉的情況下會出現此錯誤
                        reject({
                            error: 'error.503error',
                            message: '伺服器沒有開啟'
                        });

                        return;
                    }

                    response
                        .json()
                        .then(result => {
                            if (!result) {
                                reject();
                                return;
                            }

                            if(result.error) {
                                reject(result.error);
                                return;
                            }

                            // 將結果存在localStorage，如果下次取得idxpagecache.json失敗可拿出來用
                            localStorage.setItem('idxPageInfo', JSON.stringify(result));
                            dispatch(setIdxPageInfo(result));
                            resolve(result);
                        })
                        .catch(error => {

                            // 取得idxpagecache.json失敗，嘗試從localStorage取得上次資料
                            const idxPageInfo = localStorage.getItem('idxPageInfo');
                            
                            if (idxPageInfo) {
                                const _idxPageInfo = JSON.parse(idxPageInfo);
                                dispatch(setIdxPageInfo(_idxPageInfo));
                                resolve(_idxPageInfo);
                                return;
                            }

                            dispatch(getIdxPageInfoFailed(error));
                            reject(error);
                        });
                })
                .catch(error => {

                    // 手機沒有開網路、錯誤的連線網址或是server完全無法連線（包含AWS以及apache都沒有開的狀態)才會出現此錯誤
                    // 取得idxpagecache.json失敗，嘗試從localStorage取得上次資料
                    const idxPageInfo = localStorage.getItem('idxPageInfo');

                    if (idxPageInfo) {
                        const _idxPageInfo = JSON.parse(idxPageInfo);
                        dispatch(setIdxPageInfo(_idxPageInfo));
                        resolve(_idxPageInfo);
                        return;
                    }

                    dispatch(getIdxPageInfoFailed(error));
                    reject(error);
                });
        });

        return promise;

    };
}


function getTopADListStarted(payload) {
    return { type: actionTypes.getTopADListStarted, payload };
}
function setTopADList(payload) {
    return { type: actionTypes.setTopADList, payload };
}
function getTopADListFailed(payload) {
    return { type: actionTypes.getTopADListFailed, payload };
}
function getTopADList(payload = {}) {
    return dispatch => {

        dispatch(getTopADListStarted(payload)); // dispatch synced action to update loading status

        const promise = new Promise((resolve, reject) => {
            
            customAxios(TOP_AD_LIST_API_URL)
                .then(response => {
                    
                    if (response.status === 503) {
                        // server正常，apache也存活，但是resin已經被關閉的情況下會出現此錯誤
                        reject({
                            error: 'error.503error',
                            message: '伺服器沒有開啟'
                        });

                        return;
                    }
                    response
                        .json()
                        .then(result => {
                            if (!result) {
                                reject();
                                return;
                            }

                            if(result.error) {
                                reject(result.error);
                                return 
                            }

                            dispatch(setTopADList(result));
                            resolve(result);
                        })
                        .catch(error => {
                            dispatch(getTopADListFailed(error));
                            reject(error);
                        });
                })
                .catch(error => {
                    // 手機沒有開網路、錯誤的連線網址或是server完全無法連線（包含AWS以及apache都沒有開的狀態)才會出現此錯誤
                    dispatch(getTopADListFailed(error));
                    reject(error);
                });
        });
        return promise;
    };
}

function getIdxPageLotteryResultStarted(payload) {
    return { type: actionTypes.getIdxPageLotteryResultStarted, payload };
}
function getIdxPageLotteryResultSuccess(payload) {
    return { type: actionTypes.getIdxPageLotteryResultSuccess, payload };
}
function getIdxPageLotteryResultFailed(payload) {
    return { type: actionTypes.getIdxPageLotteryResultFailed, payload };
}
function getIdxPageLotteryResult(payload = {}) {
    return dispatch => {

        dispatch(getIdxPageLotteryResultStarted(payload)); // dispatch synced action to update loading status

        const promise = new Promise((resolve, reject) => {

            // TODO: 記得改API_URL與form內容
            let form = new FormData();
            form.append('op', 'getIdxPageLotteryResult');

            customAxios(IDX_PAGE_API_URL,
                    {
                        method: "POST",
                        body: form
                    }
            )
                .then(response => {
                    
                    if (response.status === 503) {
                        // server正常，apache也存活，但是resin已經被關閉的情況下會出現此錯誤
                        reject({
                            error: 'error.503error',
                            message: '伺服器沒有開啟'
                        });

                        return;
                    }
                    response
                        .json()
                        .then(result => {
                            if (!result) {
                                reject();
                                return;
                            }

                            if(result.error) {
                                reject(result.error);
                                return 
                            }

                            dispatch(getIdxPageLotteryResultSuccess({result, ...payload}));
                            resolve(result);
                        })
                        .catch(error => {
                            dispatch(getIdxPageLotteryResultFailed(error));
                            reject(error);
                        });
                })
                .catch(error => {
                    // 手機沒有開網路、錯誤的連線網址或是server完全無法連線（包含AWS以及apache都沒有開的狀態)才會出現此錯誤
                    dispatch(getIdxPageLotteryResultFailed(error));
                    reject(error);
                });
        });
        return promise;
    };
}


const actions = {
    getIdxPageInfo,
    getTopADList,
    getIdxPageLotteryResult

};

export default actions;