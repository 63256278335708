import actionTypes from "actions/types/profitEventActionTypes";
import GLOBAL from 'nonnglobal';
import customAxios from 'api/customAxios';

const API_URL = '/ProfitEventAction.do';

// 列表公告
function listProfitEventsStarted(payload) {
    return { type: actionTypes.listProfitEventsStarted, payload };
}
function listProfitEventsSuccess(payload) {
    return { type: actionTypes.listProfitEventsSuccess, payload };
}
function listProfitEventsFailed(payload) {
    return { type: actionTypes.listProfitEventsFailed, payload };
}
function listProfitEvents(payload = {}) {
    return dispatch => {

        dispatch(listProfitEventsStarted(payload)); // dispatch synced action to update loading status

        const promise = new Promise((resolve, reject) => {

            
            let form = new FormData();
            form.append('op', 'listProfitEvents');

            customAxios(API_URL,
                    {
                        method: "POST",
                        body: form
                    }
            )
                .then(response => {
                    
                    if (response.status === 503) {
                        // server正常，apache也存活，但是resin已經被關閉的情況下會出現此錯誤
                        reject({
                            error: 'error.503error',
                            message: '伺服器沒有開啟'
                        });

                        return;
                    }
                    response
                        .json()
                        .then(result => {
                            if (!result) {
                                reject();
                                return;
                            }

                            if(result.error) {
                                reject(result.error);
                                return 
                            }

                            dispatch(listProfitEventsSuccess({...result, bclass: payload.bclass}));
                            resolve(result);
                        })
                        .catch(error => {
                            dispatch(listProfitEventsFailed(error));
                            reject(error);
                        });
                })
                .catch(error => {
                    // 手機沒有開網路、錯誤的連線網址或是server完全無法連線（包含AWS以及apache都沒有開的狀態)才會出現此錯誤
                    dispatch(listProfitEventsFailed(error));
                    reject(error);
                });
        });
        return promise;
    };
}

function logProfitEventClickStarted(payload) {
    return { type: actionTypes.logProfitEventClickStarted, payload };
}
function logProfitEventClickSuccess(payload) {
    return { type: actionTypes.logProfitEventClickSuccess, payload };
}
function logProfitEventClickFailed(payload) {
    return { type: actionTypes.logProfitEventClickFailed, payload };
}
function logProfitEventClick(payload = {}) {
    return dispatch => {

        dispatch(logProfitEventClickStarted(payload)); // dispatch synced action to update loading status

        const promise = new Promise((resolve, reject) => {

            
            let form = new FormData();
            form.append('op', 'logProfitEventClick');
            form.append('eventid', payload.eventid);

            customAxios(API_URL,
                    {
                        method: "POST",
                        body: form
                    }
            )
                .then(response => {
                    
                    if (response.status === 503) {
                        // server正常，apache也存活，但是resin已經被關閉的情況下會出現此錯誤
                        reject({
                            error: 'error.503error',
                            message: '伺服器沒有開啟'
                        });

                        return;
                    }
                    response
                        .json()
                        .then(result => {
                            if (!result) {
                                reject();
                                return;
                            }

                            if(result.error) {
                                reject(result.error);
                                return 
                            }

                            dispatch(logProfitEventClickSuccess(result));
                            resolve(result);
                        })
                        .catch(error => {
                            dispatch(logProfitEventClickFailed(error));
                            reject(error);
                        });
                })
                .catch(error => {
                    // 手機沒有開網路、錯誤的連線網址或是server完全無法連線（包含AWS以及apache都沒有開的狀態)才會出現此錯誤
                    dispatch(logProfitEventClickFailed(error));
                    reject(error);
                });
        });
        return promise;
    };
}


const actions = {
 
    listProfitEvents, // 列表好康活動
    logProfitEventClick, // 點開好康活動記錄點擊

};

export default actions;