export default {
 
listCardProducts: 'listCardProducts',
listCardProductsStarted: 'listCardProductsStarted',
listCardProductsSuccess: 'listCardProductsSuccess',
listCardProductsFailed: 'listCardProductsFailed',

getProductGroups: 'getProductGroups',
getProductGroupsStarted: 'getProductGroupsStarted',
getProductGroupsSuccess: 'getProductGroupsSuccess',
getProductGroupsFailed: 'getProductGroupsFailed',

getProductInfo: 'getProductInfo',
getProductInfoStarted: 'getProductInfoStarted',
getProductInfoSuccess: 'getProductInfoSuccess',
getProductInfoFailed: 'getProductInfoFailed',

// listCardProviders: 'listCardProviders',
// listCardProvidersStarted: 'listCardProvidersStarted',
// listCardProvidersSuccess: 'listCardProvidersSuccess',
// listCardProvidersFailed: 'listCardProvidersFailed',

listFreeProducts: 'listFreeProducts',
listFreeProductsStarted: 'listFreeProductsStarted',
listFreeProductsSuccess: 'listFreeProductsSuccess',
listFreeProductsFailed: 'listFreeProductsFailed',

listVitemProducts: 'listVitemProducts',
listVitemProductsStarted: 'listVitemProductsStarted',
listVitemProductsSuccess: 'listVitemProductsSuccess',
listVitemProductsFailed: 'listVitemProductsFailed',

listBonusProducts: 'listBonusProducts',
listBonusProductsStarted: 'listBonusProductsStarted',
listBonusProductsSuccess: 'listBonusProductsSuccess',
listBonusProductsFailed: 'listBonusProductsFailed',

// getProductCategories: 'getProductCategories',
// getProductCategoriesStarted: 'getProductCategoriesStarted',
// getProductCategoriesSuccess: 'getProductCategoriesSuccess',
// getProductCategoriesFailed: 'getProductCategoriesFailed',

listRecommendedProducts: 'listRecommendedProducts',
listRecommendedProductsStarted: 'listRecommendedProductsStarted',
listRecommendedProductsSuccess: 'listRecommendedProductsSuccess',
listRecommendedProductsFailed: 'listRecommendedProductsFailed',

getIbonBlacklist: 'getIbonBlacklist',
getIbonBlacklistStarted: 'getIbonBlacklistStarted',
getIbonBlacklistSuccess: 'getIbonBlacklistSuccess',
getIbonBlacklistFailed: 'getIbonBlacklistFailed',

};
