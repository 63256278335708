export default {
  login: 'login',
  loginStarted: 'loginStarted',
  loginSuccess: 'loginSuccess',
  loginFailed: 'loginFailed',

  loginStatusExpired: 'loginStatusExpired',

  getMemberAcntInfo: 'getMemberAcntInfo',
  getMemberAcntInfoStarted: 'getMemberAcntInfoStarted',
  getMemberAcntInfoSuccess: 'getMemberAcntInfoSuccess',
  getMemberAcntInfoFailed: 'getMemberAcntInfoFailed',
    
  updMember: 'updMember',
  updMemberStarted: 'updMemberStarted',
  updMemberSuccess: 'updMemberSuccess',
  updMemberFailed: 'updMemberFailed',

  updEMailAndSendAuth: 'updEMailAndSendAuth',
  updEMailAndSendAuthStarted: 'updEMailAndSendAuthStarted',
  updEMailAndSendAuthSuccess: 'updEMailAndSendAuthSuccess',
  updEMailAndSendAuthFailed: 'updEMailAndSendAuthFailed',

  updMobilePhoneAndSendSmSAuth: 'updMobilePhoneAndSendSmSAuth',
  updMobilePhoneAndSendSmSAuthStarted: 'updMobilePhoneAndSendSmSAuthStarted',
  updMobilePhoneAndSendSmSAuthSuccess: 'updMobilePhoneAndSendSmSAuthSuccess',
  updMobilePhoneAndSendSmSAuthFailed: 'updMobilePhoneAndSendSmSAuthFailed',

  forgetPassword: 'forgetPassword',
  forgetPasswordStarted: 'forgetPasswordStarted',
  forgetPasswordSuccess: 'forgetPasswordSuccess',
  forgetPasswordFailed: 'forgetPasswordFailed',

  updPassword: 'updPassword',
  updPasswordStarted: 'updPasswordStarted',
  updPasswordSuccess: 'updPasswordSuccess',
  updPasswordFailed: 'updPasswordFailed',

  logout: 'logout',
  logoutStarted: 'logoutStarted',
  logoutSuccess: 'logoutSuccess',
  logoutFailed: 'logoutFailed',

  verifyEmailAuth: 'verifyEmailAuth',
  verifyEmailAuthStarted: 'verifyEmailAuthStarted',
  verifyEmailAuthSuccess: 'verifyEmailAuthSuccess',
  verifyEmailAuthFailed: 'verifyEmailAuthFailed',

  verifySmsAuth: 'verifySmsAuth',
  verifySmsAuthStarted: 'verifySmsAuthStarted',
  verifySmsAuthSuccess: 'verifySmsAuthSuccess',
  verifySmsAuthFailed: 'verifySmsAuthFailed',

  listLoginHistorys: 'listLoginHistorys',
  listLoginHistorysStarted: 'listLoginHistorysStarted',
  listLoginHistorysSuccess: 'listLoginHistorysSuccess',
  listLoginHistorysFailed: 'listLoginHistorysFailed',

  listMemberMsgLogs: 'listMemberMsgLogs',
  listMemberMsgLogsStarted: 'listMemberMsgLogsStarted',
  listMemberMsgLogsSuccess: 'listMemberMsgLogsSuccess',
  listMemberMsgLogsFailed: 'listMemberMsgLogsFailed',

  getMemberMsgLogDetail: 'getMemberMsgLogDetail',
  getMemberMsgLogDetailStarted: 'getMemberMsgLogDetailStarted',
  getMemberMsgLogDetailSuccess: 'getMemberMsgLogDetailSuccess',
  getMemberMsgLogDetailFailed: 'getMemberMsgLogDetailFailed',

  getMemberPageMetrics: 'getMemberPageMetrics',
  getMemberPageMetricsStarted: 'getMemberPageMetricsStarted',
  getMemberPageMetricsSuccess: 'getMemberPageMetricsSuccess',
  getMemberPageMetricsFailed: 'getMemberPageMetricsFailed',

  listTradeLogs: 'listTradeLogs',
  listTradeLogsStarted: 'listTradeLogsStarted',
  listTradeLogsSuccess: 'listTradeLogsSuccess',
  listTradeLogsFailed: 'listTradeLogsFailed',

  listRefundApplications: 'listRefundApplications',
  listRefundApplicationsStarted: 'listRefundApplicationsStarted',
  listRefundApplicationsSuccess: 'listRefundApplicationsSuccess',
  listRefundApplicationsFailed: 'listRefundApplicationsFailed',

  // listRefunds: 'listRefunds',
  // listRefundsStarted: 'listRefundsStarted',
  // listRefundsSuccess: 'listRefundsSuccess',
  // listRefundsFailed: 'listRefundsFailed', 
  
  addRefundApplication: 'addRefundApplication',
  addRefundApplicationStarted: 'addRefundApplicationStarted',
  addRefundApplicationSuccess: 'addRefundApplicationSuccess',
  addRefundApplicationFailed: 'addRefundApplicationFailed',


  listCustomerCases: 'listCustomerCases',

  getCustomerCaseReply: 'getCustomerCaseReply',
  getCustomerCaseReplyStarted: 'getCustomerCaseReplyStarted',
  getCustomerCaseReplySuccess: 'getCustomerCaseReplySuccess',
  getCustomerCaseReplyFailed: 'getCustomerCaseReplyFailed',

  createCustomerCase: 'createCustomerCase',
  createCustomerCaseStarted: 'createCustomerCaseStarted',
  createCustomerCaseSuccess: 'createCustomerCaseSuccess',
  createCustomerCaseFailed: 'createCustomerCaseFailed',

  listCustomerCaseClasses: 'listCustomerCaseClasses',
  listCustomerCaseClassesStarted: 'listCustomerCaseClassesStarted',
  listCustomerCaseClassesSuccess: 'listCustomerCaseClassesSuccess',
  listCustomerCaseClassesFailed: 'listCustomerCaseClassesFailed',

  listInvoices: 'listInvoices',
  listInvoicesStarted: 'listInvoicesStarted',
  listInvoicesSuccess: 'listInvoicesSuccess',
  listInvoicesFailed: 'listInvoicesFailed',

  updateMemberAcntInfoToStorge: 'updateMemberAcntInfoToStorge',
  getMemberMsgUnReadcntSuccess: 'getMemberMsgUnReadcntSuccess',
  setMemberMsgLogReadSuccess: 'setMemberMsgLogReadSuccess',
  updIDNumberSuccess: 'updIDNumberSuccess',
};
