export default {
    getIdxPageInfo: 'getIdxPageInfo',
    getIdxPageInfoStarted: 'getIdxPageInfoStarted',
    setIdxPageInfo: 'setIdxPageInfo',
    getIdxPageInfoFailed: 'getIdxPageInfoFailed',

    getTopADList: 'getTopADList',
    getTopADListStarted: 'getTopADListStarted',
    setTopADList: 'setTopADList',
    getTopADListFailed: 'getTopADListFailed',

    getIdxPageLotteryResult: 'getIdxPageLotteryResult',
    getIdxPageLotteryResultStarted: 'getIdxPageLotteryResultStarted',
    getIdxPageLotteryResultSuccess: 'getIdxPageLotteryResultSuccess',
    getIdxPageLotteryResultFailed: 'getIdxPageLotteryResultFailed',
    
    
};
