/*=====================================
    Root

    Author: Gray
    createtime: 2017 / 12 / 21
=====================================*/

/*--------------------------
    Import
--------------------------*/
import React, { Component } from "react";
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Route } from "react-router-dom";
import { ConnectedRouter } from 'connected-react-router';
import { GlobalStyle } from 'stylecomponents';
import actionCreators from 'actions/creators';
import CupoyRouterView from "components/commons/router/CupoyRouterView";
import CupoyRouterSwitchView from "components/commons/router/CupoyRouterSwitchView";
import TopbarView from "components/topbar/TopbarView";
import "scss/style.css"
import FooterView from 'components/footer/FooterView';
import FloatBar from 'components/floatbar/FloatBar';
import Marquee from 'components/marquee';
import styled from 'styled-components';
import SimpleAlertDialog from 'components/dialog/SimpleAlertDialog';

/**
|--------------------------------------------------
|   StyleComponent
|--------------------------------------------------
*/
const MarqueeSpace = styled.div`
    width: 100%;
    height: ${GlobalStyle.ViewSpace}px;
`;

/*--------------------------
    Main Component
--------------------------*/
class RootView extends Component {

    // ------------------------------
    // constructor
    // ------------------------------
    constructor(props) {

        super(props);

        this.state = {
            isCheckLogin: false,
            SignInDialogComponent: null,
            AlertDialogComponent: null,
            ConfirmDialogComponent: null,
            InvoiceDetailDialogComponent: null,
            ForgotAccountDialogComponent: null,
            ForgetPwdDialogComponent: null,
            AddProductDialogComponent: null,
            SystemTextMsgDialogComponent: null,
            BuyBonusPdtDialogComponent: null,
        };

        this.lazyLoadComponent = this.lazyLoadComponent.bind(this);
        this.fetchLottery = this.fetchLottery.bind(this);
    }

    // ------------------------------
    // componentDidMount
    // ------------------------------
    componentDidMount() {

        const { isLoadedIdxPageInfo } = this.props;

        this.lazyLoadComponent();
        this.checkLogin();
        this.props.webActions.checkWebState();

        if (!isLoadedIdxPageInfo) {
            this.fetchLottery();
        }
    }

    componentDidUpdate(prevProps) {

        const {
            haslogin: _haslogin,
            isLoginStatusLoading: _isLoginStatusLoading,
        } = prevProps;

        const {
            haslogin,
            isLoginStatusLoading,
        } = this.props;

        if (
            // 從有登入變為沒有登入
            (_haslogin !== haslogin && !haslogin)
            // 相同的話代表使用者不是主動登出，是被Server登出 (memberActions.loginStatusExpired)
            && _isLoginStatusLoading === isLoginStatusLoading
        ) {
            this.props.webActions.openAlertDialog({
                title: '登入逾時，請重新登入',
                description: '由於您一段時間沒有進行操作，為了您的使用安全已將您登出。',
                closeName: '重新登入',
                closeCallback: this.props.webActions.showSignInDialog,
            });
        }
    }

    fetchLottery() {
        this.props.idxPageActions.getIdxPageLotteryResult();
    }

    checkLogin() {
        this.props.memberActions.getMemberAcntInfo().then(() => {
            this.setState({
                isCheckLogin: true,
            })
        }, () => {
            this.setState({
                isCheckLogin: true,
            })
        });
    }

    // ------------------------------
    // lazyLoadComponent
    // ------------------------------
    lazyLoadComponent() {

        import('components/dialog/SignInDialog').then((SignInDialogrModule) => {
            this.setState({ SignInDialogComponent: SignInDialogrModule.default });
        }).catch(err => { console.log(err) });
        import('components/dialog/AlertDialog').then((AlertDialogModule) => {
            this.setState({ AlertDialogComponent: AlertDialogModule.default });
        }).catch(err => { console.log(err) });
        import('components/dialog/ConfirmDialog').then((ConfirmDialogModule) => {
            this.setState({ ConfirmDialogComponent: ConfirmDialogModule.default });
        }).catch(err => { console.log(err) });
        import('components/dialog/InvoiceDetailDialog').then((InvoiceDetailDialogModule) => {
            this.setState({ InvoiceDetailDialogComponent: InvoiceDetailDialogModule.default });
        }).catch(err => { console.log(err) });
        import('components/dialog/ForgotAccountDialog').then((ForgotAccountDialogModule) => {
            this.setState({ ForgotAccountDialogComponent: ForgotAccountDialogModule.default });
        }).catch(err => { console.log(err) });
        import('components/dialog/ForgetPwdDialog').then((ForgetPwdDialogModule) => {
            this.setState({ ForgetPwdDialogComponent: ForgetPwdDialogModule.default });
        }).catch(err => { console.log(err) });
        import('components/dialog/AddProductDialog').then((AddProductDialogModule) => {
            this.setState({ AddProductDialogComponent: AddProductDialogModule.default });
        }).catch(err => { console.log(err) });
        import('components/dialog/SystemTextMsgDialog').then((SystemTextMsgDialogModule) => {
            this.setState({ SystemTextMsgDialogComponent: SystemTextMsgDialogModule.default });
        }).catch(err => { console.log(err) });
        import('components/dialog/BuyBonusPdtDialog').then((BuyBonusPdtDialogModule) => {
            this.setState({ BuyBonusPdtDialogComponent: BuyBonusPdtDialogModule.default });
        }).catch(err => { console.log(err) });
    }

    // ------------------------------
    // render
    // ------------------------------
    render() {

        const {
            history,
            signInDialog,
            alertDialog,
            confirmDialog,
            lotterys,
            haslogin,
            cartItems = [],
            invoiceDetailDialog,
            forgotAccountDialog,
            forgetPwdDialog,
            addProductDialog,
            systemTextMsgDialog,
            buyBonusPdtDialog,
            isCheckedWebState,
            FRONTWEB,
        } = this.props;

        const {
            isCheckLogin,
            SignInDialogComponent,
            AlertDialogComponent,
            ConfirmDialogComponent,
            InvoiceDetailDialogComponent,
            ForgotAccountDialogComponent,
            ForgetPwdDialogComponent,
            AddProductDialogComponent,
            SystemTextMsgDialogComponent,
            BuyBonusPdtDialogComponent,
        } = this.state;

        const cartCount = cartItems.length;

        return (
            <CupoyRouterView>
                <ConnectedRouter history={history}>
                    <div>
                        <GlobalStyle.Container>

                            {isCheckedWebState && FRONTWEB && lotterys && (lotterys.length > 0)
                                ? <Marquee lotterys={lotterys} rate={60} />
                                : <MarqueeSpace />}

                            {isCheckLogin && (
                                <Route component={CupoyRouterSwitchView} />
                            )}

                        </GlobalStyle.Container>

                        <TopbarView />

                        <FooterView />

                        {isCheckedWebState && FRONTWEB && (
                            <React.Fragment>
                                <FloatBar haslogin={haslogin} cartCount={cartCount} />
                                {signInDialog.isOpen && SignInDialogComponent && <SignInDialogComponent />}
                                {alertDialog.isOpen && AlertDialogComponent && <AlertDialogComponent />}
                                {confirmDialog.isOpen && ConfirmDialogComponent && <ConfirmDialogComponent />}
                                {invoiceDetailDialog.isOpen && InvoiceDetailDialogComponent && <InvoiceDetailDialogComponent />}
                                {forgotAccountDialog.isOpen && ForgotAccountDialogComponent && <ForgotAccountDialogComponent />}
                                {forgetPwdDialog.isOpen && ForgetPwdDialogComponent && <ForgetPwdDialogComponent />}
                                {addProductDialog.isOpen && AddProductDialogComponent && <AddProductDialogComponent />}
                                {systemTextMsgDialog.isOpen && SystemTextMsgDialogComponent && <SystemTextMsgDialogComponent />}
                                {buyBonusPdtDialog.isOpen && BuyBonusPdtDialogComponent && <BuyBonusPdtDialogComponent />}
                                <SimpleAlertDialog />
                            </React.Fragment>
                        )}

                    </div>
                </ConnectedRouter>
            </CupoyRouterView>
        );
    }
}

/*--------------------------
    Reducer Props
--------------------------*/
const mapStateToProps = function (state) {
    return {
        isLoginStatusLoading: state.member.isloading,
        history: state.web.history,
        signInDialog: state.web.signInDialog,
        alertDialog: state.web.alertDialog,
        confirmDialog: state.web.confirmDialog,
        isLoadedIdxPageInfo: state.idxpage.isLoadedIdxPageInfo,
        lotterys: state.idxpage.lotterys,
        haslogin: state.member.haslogin,
        cartItems: state.cardcart.cartItems,
        invoiceDetailDialog: state.web.invoiceDetailDialog,
        forgotAccountDialog: state.web.forgotAccountDialog,
        forgetPwdDialog: state.web.forgetPwdDialog,
        addProductDialog: state.web.addProductDialog,
        systemTextMsgDialog: state.web.systemTextMsgDialog,
        FRONTWEB: state.web.FRONTWEB,
        isCheckedWebState: state.web.isCheckedWebState,
        buyBonusPdtDialog: state.web.buyBonusPdtDialog,
    };
};

/*--------------------------
    Reducer Action
--------------------------*/
const mapActionToProps = function (dispatch) {
    return {
        webActions: bindActionCreators(actionCreators.webActionCreators, dispatch),
        memberActions: bindActionCreators(actionCreators.memberActionCreators, dispatch),
        idxPageActions: bindActionCreators(actionCreators.idxPageActionCreators, dispatch),
    };
};

export default connect(mapStateToProps, mapActionToProps, null, {
    forwardRef: true
})(RootView);
