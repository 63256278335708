import actionTypes from "actions/types/cardCartActionTypes";
import convetDBResultSetToArray from "actions/util/convetDBResultSetToArray";
import {uniq} from 'lodash';
/*==========================
    Reducers
==========================*/
const reducers = {
    [actionTypes.listCardCartItemsSuccess]: function(state, action) {
        return listCardCartItemsSuccess(state, action);
    },
    [actionTypes.addCardToCartSuccess]: function(state, action) {
        return addCardToCartSuccess(state, action);
    },
    [actionTypes.delCardFromCartSuccess]: function(state, action) {
        return delCardFromCartSuccess(state, action);
    },
    [actionTypes.buildCartOrderSuccess]: function(state, action) {
        return buildCartOrderSuccess(state, action);
    },
    [actionTypes.payCardCartSuccess]: function(state, action) {
        return payCardCartSuccess(state, action);
    },
    [actionTypes.listCardCartUnfinishedOrdersSuccess]: function(state, action) {
        return listCardCartUnfinishedOrdersSuccess(state, action);
    },
    [actionTypes.payCardCartUnfinishedOrderSuccess]: function(state, action) {
        return payCardCartUnfinishedOrderSuccess(state, action);
    },
    [actionTypes.fetchCartProductImageSuccess]: function(state, action) {
        return fetchCartProductImageSuccess(state, action);
    },
};





/*==========================
    Method
==========================*/

function payCardCartUnfinishedOrderSuccess (state, action) {

    const payload = action.payload;
    const {
        cartid,
        result,
    } = payload;


    const new_unfinishedCartMap = Object.assign({}, state.unfinishedCartMap, {
        [cartid]: result,
    });

    return Object.assign({}, state, {
        unfinishedCartMap: new_unfinishedCartMap,
        isloading: false,
	});
}


//   // 購物車訂單付款狀態
//   public static final int CartPayStatus_UnPay = 0; // 尚未付款
//   public static final int CartPayStatus_Pay = 1; // 付款成功
//   public static final int CartPayStatus_Fail = 2; // 付款失敗
  
//   // 購物車訂單狀態
//   public static final int CartOrderStatus_UnPay = 0; // 等待付款
//   public static final int CartOrderStatus_Success = 1; // 交易成功
//   public static final int CartOrderStatus_Fail = 2; // 訂單建立失敗
//   public static final int CartOrderStatus_PartFail = 3; // 部分訂單建立失敗
//   public static final int CartOrderStatus_Processed = 4; // 購物車已處理過(付款狀態微已付款或訂單建立完成或部分訂單已建立)
//   public static final int CartOrderStatus_ExpiredPay = 5; // 付款時間已於購物車有效時間;
//   public static final int CartOrderStatus_InventoryShortage = 6; // 部分商品庫存不足，訂單尚未建立
//   // 點卡訂單付款狀態
//   public static final int CardOrderPayStatus_Shipped = 1; // 付款成功，已出貨
//   public static final int CardOrderPayStatus_UnShipping = 2; // 付款成功，尚未出貨

function listCardCartUnfinishedOrdersSuccess (state, action) {

    const { refresh = false } = action.payload;

    const dbresult = convetDBResultSetToArray(action.payload.result);

    const unfinishedCartMap = dbresult.reduce((map, order)=>{
        map[order.cartid] = Object.assign({}, state.unfinishedCartMap[order.cartid], order);

        // 將商品資訊json轉為object
        if(map[order.cartid].pdtinfo && typeof map[order.cartid].pdtinfo === 'string') {
            map[order.cartid].pdtinfo = JSON.parse(map[order.cartid].pdtinfo);
        }

        return map;
    }, {});
    

    const ids = dbresult.map((order, idx)=>{
        return order.cartid;
    });

    const newids = uniq([...state.unfinishedCartIds, ...ids]);

    const newOrderMap = Object.assign({}, state.unfinishedCartMap, unfinishedCartMap);

    return Object.assign({}, state, {
        unfinishedCartIds: !refresh ? newids : ids,
        unfinishedCartMap: newOrderMap,
        isloading: false,
    });
}

function payCardCartSuccess (state, action) {
    const cartOrder = action.payload;

    return Object.assign({}, state, {
        cartOrder,
        isloading: false,
	});
}

function buildCartOrderSuccess (state, action) {
    
    const cartOrder = action.payload;

    return Object.assign({}, state, {
        cartOrder,
        isloading: false,
	});
}

function delCardFromCartSuccess (state, action) {

    const cartItems = action.payload;

    return Object.assign({}, state, {
        cartItems,
        isloading: false,
	});
}

function addCardToCartSuccess (state, action) {
    
    const cartItems = action.payload;

    return Object.assign({}, state, {
        cartItems,
        isloading: false,
	});
}


function listCardCartItemsSuccess (state, action) {
    
    const cartItems = action.payload;

    return Object.assign({}, state, {
        cartItems,
        isloading: false,
        isloaded: true,
	});
}

function fetchCartProductImageSuccess(state, { payload }) {

    const { result } = payload;
    const dbResult = convetDBResultSetToArray(result);

    const { cartProductImages } = state;

    let new_cartProductImages = Object.assign({}, cartProductImages);


    for (let idx = 0; idx < dbResult.length; idx++) {
        const item = dbResult[idx];
        const { productid, thumbimg } = item;

        new_cartProductImages = Object.assign({}, new_cartProductImages, {
            [productid]: thumbimg,
        });
    }

    const new_state = Object.assign({}, state, {
        cartProductImages: new_cartProductImages,
        isloading: false,
    });

    return new_state;
}


// 操作失敗
function actionFailed (state, action) {

    if(state.error === action.payload){
        return state;
    }

    const error = action.payload;

    return Object.assign({}, state, {
        isloading: false,
        error,
	});


}


// 操作中
function actionStarted (state, action) {

    if(state.isloading){
        return state;
    }

    return Object.assign({}, state, {
        isloading: true,
	});


}


/*==========================
    Export
==========================*/
export default function createReducers (initialState) {
	return function reducer(state = initialState, action) {
        if(!actionTypes.hasOwnProperty(action.type)){
            return state;
        }
		if (reducers.hasOwnProperty(action.type)) {
            return reducers[action.type](state, action);
		} else if(action.type.lastIndexOf('Started') !== -1){
			return actionStarted(state, action);            
		} else if(action.type.lastIndexOf('Failed') !== -1){
			return actionFailed(state, action);            
        } else {
			return state;
		}
	};
}
