/*=====================================
    RootSwitchView

    Author: Gray
    createtime: 2017 / 12 / 21
=====================================*/

/*--------------------------
    Import
--------------------------*/
import React, { Component } from "react";
import { Route, Switch } from "react-router-dom";
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import actionCreators from 'actions/creators';
import CupoyRouter from "components/commons/router/CupoyRouter";
import CupoyRouterLazyLoadingView from 'components/commons/router/CupoyRouterLazyLoadingView';
import { GlobalStyle } from 'stylecomponents';
import RepairView from 'components/RepairView';

/*--------------------------
    Style Component
--------------------------*/

/*--------------------------
    Variables
--------------------------*/

/*--------------------------
    Main Component
--------------------------*/
class CupoyRouterSwitchView extends Component {

    // ------------------------------
    // constructor
    // ------------------------------
    constructor(props) {
        super(props);

        this.state = {
            HomeViewComponent: null,
            MemberSignUpViewComponent: null,
            MemberViewComponent: null,
            NewsComponent: null,
            ProfiteventComponent: null,
            GameComponent: null,
            BonusProductsComponent: null,
            HelpViewComponent: null,
            FreeProductsComponent: null,
            VitemProductsComponent: null,
            PointcardComponent: null,
            SaleDetailViewComponent: null,
            PurchaseDetailViewComponent: null,
            ProductCheckoutViewComponent: null, 
            ResetPwdViewComponent: null,
            MailIdentifyViewComponent: null,
            NewProductsViewComponent: null,
            ProductViewComponent: null,
            WebATMPayerViewComponent: null,
            MemberCarrierPlatformViewComponent: null,
            ThirdPartyResultViewComponent: null,
        };

        this.previousLocation = undefined;

        this.loadFirstViewComponent = this.loadFirstViewComponent.bind(this);
        this.lazyLoadingComponent = this.lazyLoadingComponent.bind(this);
    }

    // ------------------------------
    // componentDidMount
    // ------------------------------
    componentDidMount () {

        this.loadFirstViewComponent();
        this.lazyLoadingComponent();
    }

    // ------------------------------
    // loadFirstViewComponent
    // ------------------------------
    loadFirstViewComponent() {

        if(CupoyRouter.includes(CupoyRouter.STATES.HOME)) {
            import('components/home/HomeView').then((HomeModule) => {
                this.setState({ HomeViewComponent: HomeModule.default });
            }).catch(err => { console.log(err) });
        }       
    }

    // ------------------------------
    // lazyLoadingComponent
    // ------------------------------
    lazyLoadingComponent () {

        if(!CupoyRouter.includes(CupoyRouter.STATES.HOME)) {
            import('components/home/HomeView').then((HomeModule) => {
                this.setState({ HomeViewComponent: HomeModule.default });
            }).catch(err => { console.log(err) });
        }

        import('components/member/MemberView').then(MemberViewModule => {
            this.setState({ MemberViewComponent: MemberViewModule.default });
        }).catch(err => console.log(err));
        import('components/member/MemberSignUpView').then(MemberSignUpModule => {
            this.setState({ MemberSignUpViewComponent: MemberSignUpModule.default });
        }).catch(err => console.log(err));
        import('components/news/NewsView').then(NewsModule => {
            this.setState({ NewsComponent: NewsModule.default });
        }).catch(err => console.log(err));
        import('components/profitevent/ProfiteventView').then(ProfiteventModule => {
            this.setState({ ProfiteventComponent: ProfiteventModule.default });
        }).catch(err => console.log(err));
        import('components/game/GameView').then(GameModule => {
            this.setState({ GameComponent: GameModule.default });
        }).catch(err => console.log(err));
        import('components/bonusproducts/BonusProductsView').then(BonusProductsModule => {
            this.setState({ BonusProductsComponent: BonusProductsModule.default });
        });
        import('components/freeproducts/FreeProductsView').then(FreeProductsModule => {
            this.setState({ FreeProductsComponent: FreeProductsModule.default });
        });
        import('components/vitemproducts/VitemProductsView').then(VitemProductsModule => {
            this.setState({ VitemProductsComponent: VitemProductsModule.default });
        });
        import('components/pointcard/PointcardView').then(PointcardModule => {
            this.setState({ PointcardComponent: PointcardModule.default });
        })
        import('components/help/HelpView').then(HelpModule => {
            this.setState({ HelpViewComponent: HelpModule.default });
        }).catch(err => console.log(err));

        import('components/game/SaleDetailView').then(SaleDetailModule => {
            this.setState({ SaleDetailViewComponent: SaleDetailModule.default });
        }).catch(err => console.log(err));
        import('components/game/PurchaseDetailView').then(PurchaseDetailModule => {
            this.setState({ PurchaseDetailViewComponent: PurchaseDetailModule.default });
        }).catch(err => console.log(err));
        import('components/game/ProductCheckoutView').then(MemberProductCheckoutModule => {
            this.setState({ ProductCheckoutViewComponent: MemberProductCheckoutModule.default });
        });
        import('components/member/ResetPwdView').then(ResetPwdModule => {
            this.setState({ ResetPwdViewComponent: ResetPwdModule.default });
        });
        import('components/member/MailIdentifyView').then(MailIdentifyModule => {
            this.setState({ MailIdentifyViewComponent: MailIdentifyModule.default });
        });
        import('components/newproducts/NewProductsView').then(NewProductsModule => {
            this.setState({ NewProductsViewComponent: NewProductsModule.default });
        });
        import('components/product/ProductView').then(ProductModule => {
            this.setState({ ProductViewComponent: ProductModule.default });
        });
        import('components/payment/WebATMPayerView').then(WebATMPayerModule => {
            this.setState({ WebATMPayerViewComponent: WebATMPayerModule.default });
        });
        import('components/member/MemberCarrierPlatformView').then(MemberCarrierPlatformViewModule => {
            this.setState({ MemberCarrierPlatformViewComponent: MemberCarrierPlatformViewModule.default });
        });
        import('components/ThirdPartyResultView').then(ThirdPartyResultViewModule => {
            this.setState({ ThirdPartyResultViewComponent: ThirdPartyResultViewModule.default });
        });
    }
    
    // ------------------------------
    // render
    // ------------------------------    
    render() {

        const {
            FRONTWEB,
            isCheckedWebState,
        } = this.props;

        const {
            HomeViewComponent,
            MemberSignUpViewComponent,
            MemberViewComponent,
            NewsComponent,
            ProfiteventComponent,
            GameComponent,
            BonusProductsComponent,
            HelpViewComponent,
            FreeProductsComponent,
            VitemProductsComponent,
            PointcardComponent,
            SaleDetailViewComponent,
            PurchaseDetailViewComponent,
            ProductCheckoutViewComponent,
            ResetPwdViewComponent,
            MailIdentifyViewComponent,
            NewProductsViewComponent,
            ProductViewComponent,
            WebATMPayerViewComponent,
            MemberCarrierPlatformViewComponent,
            ThirdPartyResultViewComponent,
        } = this.state;

        const { location } = this.props;
        const isModal = !!(location.state && location.state.modal && this.previousLocation && this.previousLocation !== location);

        if (!isCheckedWebState) {
            return (
                <GlobalStyle.SubContainer>
                    <GlobalStyle.LoadingContainer>
                        <GlobalStyle.Spin size='32px' />
                    </GlobalStyle.LoadingContainer>
                </GlobalStyle.SubContainer>
            );
        }

        if (!FRONTWEB) {
            return (
                <GlobalStyle.SubContainer>
                    <RepairView />
                </GlobalStyle.SubContainer>
            );
        }

        return (
            <div>
                <Switch location={isModal ? this.previousLocation : location}>
                    {HomeViewComponent ? <Route path={CupoyRouter.PATHS.HOME} component={HomeViewComponent} exact /> : <Route path={CupoyRouter.PATHS.HOME} component={CupoyRouterLazyLoadingView} exact />}
                    {MemberSignUpViewComponent ? <Route path={CupoyRouter.PATHS.REGISTER} component={MemberSignUpViewComponent} exact /> : <Route path={CupoyRouter.PATHS.REGISTER} component={CupoyRouterLazyLoadingView} exact />}
                    {MemberViewComponent ? <Route path={CupoyRouter.PATHS.MEMBER} component={MemberViewComponent} /> : <Route path={CupoyRouter.PATHS.MEMBER} component={CupoyRouterLazyLoadingView} />}
                    {NewsComponent ? <Route path={CupoyRouter.PATHS.NEWS} component={NewsComponent} /> : <Route path={CupoyRouter.PATHS.NEWS} component={CupoyRouterLazyLoadingView} />}
                    {ProfiteventComponent ? <Route path={CupoyRouter.PATHS.PROFITEVENT} component={ProfiteventComponent} exact /> : <Route path={CupoyRouter.PATHS.PROFITEVENT} component={CupoyRouterLazyLoadingView} exact />}
                    {GameComponent? <Route path={CupoyRouter.PATHS.GAME} component={GameComponent} /> : <Route path={CupoyRouter.PATHS.GAME} component={CupoyRouterLazyLoadingView} />}
                    {BonusProductsComponent? <Route path={CupoyRouter.PATHS.BONUSPRODUCTS} component={BonusProductsComponent} /> : <Route path={CupoyRouter.PATHS.BONUSPRODUCTS} component={CupoyRouterLazyLoadingView} />}
                    {FreeProductsComponent? <Route path={CupoyRouter.PATHS.FREEPRODUCTS} component={FreeProductsComponent} /> : <Route path={CupoyRouter.PATHS.FREEPRODUCTS} component={CupoyRouterLazyLoadingView} />}
                    {VitemProductsComponent? <Route path={CupoyRouter.PATHS.VITEMPRODUCTS} component={VitemProductsComponent} /> : <Route path={CupoyRouter.PATHS.VITEMPRODUCTS} component={CupoyRouterLazyLoadingView} />}
                    {PointcardComponent? <Route path={CupoyRouter.PATHS.POINTCARD} component={PointcardComponent} /> : <Route path={CupoyRouter.PATHS.POINTCARD} component={CupoyRouterLazyLoadingView} />}
                    {HelpViewComponent ? <Route path={CupoyRouter.PATHS.HELP} component={HelpViewComponent} /> : <Route path={CupoyRouter.PATHS.HELP} component={CupoyRouterLazyLoadingView} />}
                    {SaleDetailViewComponent ? <Route path={CupoyRouter.PATHS.GAME_SALE_DETAIL} component={SaleDetailViewComponent} /> : <Route path={CupoyRouter.PATHS.GAME_SALE_DETAIL} component={CupoyRouterLazyLoadingView} />}
                    {PurchaseDetailViewComponent ? <Route path={CupoyRouter.PATHS.GAME_PURCHASE_DETAIL} component={PurchaseDetailViewComponent} /> : <Route path={CupoyRouter.PATHS.GAME_PURCHASE_DETAIL} component={CupoyRouterLazyLoadingView} />}
                    {ProductCheckoutViewComponent ? <Route path={CupoyRouter.PATHS.PRODUCT_CHECKOUT} component={ProductCheckoutViewComponent} /> : <Route path={CupoyRouter.PATHS.PRODUCT_CHECKOUT} component={CupoyRouterLazyLoadingView} />}
                    {ResetPwdViewComponent ? <Route path={CupoyRouter.PATHS.RESET_PWD} component={ResetPwdViewComponent} /> : <Route path={CupoyRouter.PATHS.RESET_PWD} component={CupoyRouterLazyLoadingView} exact />}
                    {MailIdentifyViewComponent ? <Route path={CupoyRouter.PATHS.MAIL_IDENTIFY} component={MailIdentifyViewComponent} /> : <Route path={CupoyRouter.PATHS.MAIL_IDENTIFY} component={CupoyRouterLazyLoadingView} exact />}
                    {MailIdentifyViewComponent ? <Route path={CupoyRouter.PATHS.CHANGE_MAIL_IDENTIFY} component={MailIdentifyViewComponent} /> : <Route path={CupoyRouter.PATHS.CHANGE_MAIL_IDENTIFY} component={CupoyRouterLazyLoadingView} exact />}
                    {NewProductsViewComponent ? <Route path={CupoyRouter.PATHS.NEW_PRODUCTS} component={NewProductsViewComponent} /> : <Route path={CupoyRouter.PATHS.NEW_PRODUCTS} component={CupoyRouterLazyLoadingView} exact />}
                    {ProductViewComponent ? <Route path={CupoyRouter.PATHS.POINTCARD_PRODUCT} component={ProductViewComponent} /> : <Route path={CupoyRouter.PATHS.POINTCARD_PRODUCT} component={CupoyRouterLazyLoadingView} exact />}
                    {ProductViewComponent ? <Route path={CupoyRouter.PATHS.VITEM_PRODUCT} component={ProductViewComponent} /> : <Route path={CupoyRouter.PATHS.VITEM_PRODUCT} component={CupoyRouterLazyLoadingView} exact />}
                    {ProductViewComponent ? <Route path={CupoyRouter.PATHS.FREE_PRODUCT} component={ProductViewComponent} /> : <Route path={CupoyRouter.PATHS.FREE_PRODUCT} component={CupoyRouterLazyLoadingView} exact />}
                    {ProductViewComponent ? <Route path={CupoyRouter.PATHS.BONUS_PRODUCT} component={ProductViewComponent} /> : <Route path={CupoyRouter.PATHS.BONUS_PRODUCT} component={CupoyRouterLazyLoadingView} exact />}
                    {WebATMPayerViewComponent ? <Route path={CupoyRouter.PATHS.WEBATM_PAYER} component={WebATMPayerViewComponent} /> : <Route path={CupoyRouter.PATHS.WEBATM_PAYER} component={CupoyRouterLazyLoadingView} exact />}
                    {MemberCarrierPlatformViewComponent ? <Route path={CupoyRouter.PATHS.MEMBER_CARRIER_PLATFORM} component={MemberCarrierPlatformViewComponent} /> : <Route path={CupoyRouter.PATHS.MEMBER_CARRIER_PLATFORM} component={CupoyRouterLazyLoadingView} exact />}
                    {ThirdPartyResultViewComponent ? <Route path={CupoyRouter.PATHS.THIRD_PARTY_RESULT} component={ThirdPartyResultViewComponent} /> : <Route path={CupoyRouter.PATHS.THIRD_PARTY_RESULT} component={CupoyRouterLazyLoadingView} exact />}
                </Switch>
            </div>
        );
    }
}

/*--------------------------
    Reducer Props
--------------------------*/
const mapStateToProps = function(state) {
    return {
        FRONTWEB: state.web.FRONTWEB,
        isCheckedWebState: state.web.isCheckedWebState,
    };
};

/*--------------------------
    Reducer Action
--------------------------*/
const mapActionToProps = function(dispatch) {
    return {
        webActions: bindActionCreators(actionCreators.webActionCreators, dispatch),
    };
};

export default connect(mapStateToProps, mapActionToProps)(CupoyRouterSwitchView);
