import styled from 'styled-components';

const Img = styled.div`
  background-image: url(${props => props.img});
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  background-color: #ccc;
`

export default Img;