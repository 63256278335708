import React, { PureComponent } from "react";

// import { bindActionCreators } from 'redux';
// import { connect } from 'react-redux';
// import { NavLink } from 'react-router-dom';
import styled from 'styled-components';

// import actionCreators from "actions/creators";
import { GlobalStyle } from 'stylecomponents';

// import I18N from 'components/commons/I18N';

const Container = styled.div`
	position: relative;
	width: 100%;	
	min-height: 500px;
	display: flex;
	align-items: center;
	justify-content: center;
`;

class CupoyRouterLazyLoadingView extends PureComponent {
	
    render() {
        return (            
    		<Container>
                <GlobalStyle.LoadingSpinCenter>
    			    <GlobalStyle.Spin size="24px" />
                </GlobalStyle.LoadingSpinCenter>
    		</Container>
    	)
    }

}

export default CupoyRouterLazyLoadingView;