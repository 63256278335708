import styled from 'styled-components';
import Colors from './colors';

const Table = styled.table`
    margin: 0;
    border: none;
`;
const TableHeader = styled.thead`
    display: table;
    background-color: #FFF;
    width: 100%;
    height: 60px;
    border-bottom: 1px solid #D9D9D9;
    color: #4A4A4A;
    font-size: 15px;
`;
const TableBody = styled.tbody`
    display: table;
    width: 100%;
`;
const TableHeading = styled.th`
    text-align: center;
    vertical-align: middle;
    background-color: #FFF;
    font-weight: 600;
    border: none;
`;
const TableData = styled.td`
    height: 68px;
    text-align: center;
    border: none;
    vertical-align: middle;
`;
const TableRow = styled.tr`
    &:not(:last-child) {
        border-bottom: 1px solid #D9D9D9;
    }
`;
const Label = styled.div`
    width: 60px;
    height: 28px;
    text-align: center;
    line-height: 26px;
    background-color: ${({ active }) => active ? Colors.MAIN_COLOR : 'transparent'};
    color: ${({ active }) => active ? '#FFF' : Colors.MAIN_COLOR};
    border: 1px solid ${Colors.MAIN_COLOR};
    border-radius: 2px;
    margin: auto;
`;

const table = {
    Table,
    TableHeader,
    TableBody,
    TableHeading,
    TableData,
    TableRow,
    Label,
};

export default table;