export default {

    listCardOrders: 'listCardOrders',
    listCardOrdersStarted: 'listCardOrdersStarted',
    listCardOrdersSuccess: 'listCardOrdersSuccess',
    listCardOrdersFailed: 'listCardOrdersFailed',

    qyCardOrderDetail: 'qyCardOrderDetail',
    qyCardOrderDetailStarted: 'qyCardOrderDetailStarted',
    qyCardOrderDetailSuccess: 'qyCardOrderDetailSuccess',
    qyCardOrderDetailFailed: 'qyCardOrderDetailFailed',

    setCardUsedFlag: 'setCardUsedFlag',
    setCardUsedFlagStarted: 'setCardUsedFlagStarted',
    setCardUsedFlagSuccess: 'setCardUsedFlagSuccess',
    setCardUsedFlagFailed: 'setCardUsedFlagFailed',

    listBonusOrders: 'listBonusOrders',
    listBonusOrdersStarted: 'listBonusOrdersStarted',
    listBonusOrdersSuccess: 'listBonusOrdersSuccess',
    listBonusOrdersFailed: 'listBonusOrdersFailed',

    buyFreeCardProduct: 'buyFreeCardProduct',
    buyFreeCardProductStarted: 'buyFreeCardProductStarted',
    buyFreeCardProductSuccess: 'buyFreeCardProductSuccess',
    buyFreeCardProductFailed: 'buyFreeCardProductFailed',

};
