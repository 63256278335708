/*=====================================
    TopbarView

    Author: Gray
    createtime: 2017 / 12 / 25
=====================================*/

/*--------------------------
    Import
--------------------------*/
import React, { PureComponent } from "react";
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import actionCreators from "actions/creators";
import styled from 'styled-components';
import { GlobalStyle, Colors } from 'stylecomponents';
import CupoyImage from 'components/commons/CupoyImage';
import Dropdown from 'components/commons/Dropdown';
import CupoyRouter from "components/commons/router/CupoyRouter";
import CupoySrefView from "components/commons/router/CupoySrefView";
import GLOBAL from 'nonnglobal';
/**
|--------------------------------------------------
| Variables
|--------------------------------------------------

/*--------------------------
    Style Component
--------------------------*/
const Content = styled.div`
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    width: ${GlobalStyle.ContainerWidth};
    max-width: ${GlobalStyle.ContainerMaxWidth}px;
    height: 79px;
    margin: 0 auto;
`;
const SubTopbar = styled.div`
    width: 100%;
    height: 40px;
    background: #F8F8F8;
    box-shadow: 0 1px 0 0 #EBEBEB;
    font-size: 12px;
    color: #222;
`;
const SubTopbarContainer = styled.div`
    display: flex;
    flex-direction: row-reverse;
    width: ${GlobalStyle.ContainerWidth};
    max-width: ${GlobalStyle.ContainerMaxWidth}px;
    margin: 0 auto;
`;
const SubTopbarNavItemWrapper = styled.div`
    display: inline-flex;
    align-items: center;
    position: relative;
    cursor: pointer;

    &::after {
        content: '';
        display: block;
        width: 1px;
        height: 17px;
        background-color: #DDD;
    }
`;
const SubTopbarNavItem = styled.div`
    display: inline-flex;
    align-items: center;
    padding: 0 15px;
    height: 40px;
    color: #222;

    &:hover {
        color: #000;
    }
`;
const SubTopbarNavItemCount = styled.div`
    position: absolute;
    top: 6px;
    right: 2px;
    width: 16px;
    height: 16px;
    border-radius: 50%;
    color: #FFF;
    background-color: ${Colors.MAIN_COLOR};
    line-height: 16px;
    text-align: center;
    font-size: 12px;
`;
const MemberWrapper = styled.div`
    position: relative;
    display: inline-flex;
    align-items: center;
    padding-left: 15px;
    height: 40px;
    cursor: pointer;
    user-select: none;
`;
const Avatar = styled.div`
    width: 24px;
    height: 24px;
    border-radius: 50%;
    background-color: #E2DFDE;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    margin-right: 10px;
`;
const DropdownIcon = styled.div`
    margin-left: 6px;
    font-size: 17px;
`;
const ShoppingCartIcon = styled.div`
    margin-right: 6px;
    font-size: 15px;
`;
const LogoWrapper = styled.div`
    position: absolute;
    left: 0;
    bottom: 16px;
`;
const Logo = styled.div`
    width: 188px;
    height: 87px;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    cursor: pointer;
`;
const Nav = styled.nav`
    margin-left: ${188 + 30}px;
`;
const NavList = styled.ul`
    display: flex;
    flex-direction: row;
    margin: 0 0 1px 0;
`;
const NavItemTouch = styled.div`
    height: 60px;
    line-height: 60px;
    color: inherit;
`;
const NavItem = styled.li`
    margin-right: 35px;
    font-size: 16px;
    border-bottom-color: ${props => props.isActive ? Colors.MAIN_COLOR : 'transparent'};
    border-bottom-width: 2px;
    border-bottom-style: solid;
    cursor: pointer;

    ${NavItemTouch} {
        color: ${({ isActive, isRed }) => isActive ? Colors.MAIN_COLOR : isRed ? Colors.Red : '#4A4A4A'};
    }

    &:last-child {
        margin-right: 0;
    }
`;
const MemberDropdown = styled.ul`
    background: #FFF;
    border: 1px solid #DFE3E9;
    box-shadow: 0 1px 4px 0 rgba(0,0,0,0.08);
    border-radius: 4px;
    margin: 0;

    &::after {
        content: '';
        display: block;
        position: absolute;
        top: 0;
        right: 15px;
        width: 14px;
        height: 3px;
        background-color: #FFF;
        z-index: 1;
    }

    &::before {
        content: '';
        display: block;
        position: absolute;
        top: -6px;
        right: 16px;
        width: 10px;
        height: 10px;
        transform: rotateZ(45deg);
        background-color: #FFF;
        border: 1px solid #DFE3E9;
        box-shadow: 0 1px 4px 0 rgba(0,0,0,0.08);
        z-index: -1;
    }
`;
const MemberDropdownListItem = styled.li`
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 183px;
    height: 38px;
    border-bottom: 1px solid #E5E5E5;
    font-size: 14px;
    color: #4A4A4A;
    cursor: pointer;

    &:hover {
        color: #222;
    }

    &:last-child {
        border-bottom: none;
    }
`;
const MemberDropdownIcon = styled.div`
    width: 50px;
    display: flex;
    justify-content: center;
    left: 16px;
    color: #D9D9D9;
    font-size: ${props => props.size ? `${props.size}px` : '12px'};
`;

/*--------------------------
    Main Component
--------------------------*/
class TopbarView extends PureComponent {

    // ------------------------------
    // constructor
    // ------------------------------
    constructor(props) {
        super(props);

        this.hideProfileDropdown = this.hideProfileDropdown.bind(this);
        this.showSignInDialog = this.showSignInDialog.bind(this);
        this.logout = this.logout.bind(this);
        this.logoutConfirmCallback = this.logoutConfirmCallback.bind(this);

        this.PROFILE_NAVS = [
            {
                name: '個人資訊',
                stateName: CupoyRouter.STATES.MEMBER_INFO,
                iconName: 'zmdi zmdi-account-o',
                iconSize: 24,
            },
            {
                name: '系統訊息',
                stateName: CupoyRouter.STATES.MEMBER_SYSTEM_MESSAGE,
                iconName: 'zmdi zmdi-comment-outline',
                iconSize: 19,
            },
            {
                name: '我的訂單',
                stateName: CupoyRouter.STATES.MEMBER_CARD_ORDER_LIST_FINISH,
                iconName: 'zmdi zmdi-file',
                iconSize: 20,
            },
            {
                name: '發財金',
                stateName: CupoyRouter.STATES.MEMBER_BONUS_LOGS,
                iconName: 'zmdi zmdi-money',
                iconSize: 20,
            },
            {
                name: '安全登出',
                iconName: 'zmdi zmdi-power',
                iconSize: 20,
                action: this.logout,
            },
        ];

        this.state = {
            pathname: '',
        };
    }

    // ------------------------------
    // componentDidMount
    // ------------------------------
    componentDidMount() {

        // 這邊是更新state強制topbar重新繪製，不然active狀態不會更新
        this.props.history.listen(location => {
            this.setState({
                pathname: location.pathname
            })
        });
    }

    hideProfileDropdown (){
        this.profileDropdownRef && this.profileDropdownRef.hide();
    }

    showSignInDialog (){
        this.props.webActions.showSignInDialog();
    }

    logout(){
        this.props.webActions.openConfirmDialog({
            title: '登出 9199',
            description: '確定要登出？',
            confirmCallback: this.logoutConfirmCallback,
            confirmName: '登出',
            cancelName: '取消',
        });
        this.hideProfileDropdown();
    }

    logoutConfirmCallback(){
        this.props.memberActions.logout().then(() => {
            window.location.href = '/';
        });
    }

    // ------------------------------
    // render
    // ------------------------------
    render() {
        
        return (
            <GlobalStyle.Topbar>
                {this.renderSubTopbar()}
                <Content>
                    <CupoySrefView
                        statename={CupoyRouter.STATES.HOME}
                        stylecomponent={LogoWrapper}
                    >
                        <CupoyImage 
                            src={'images/logo.png'}
                            stylecomponent={Logo}
                            isBackgroundImage
                        />
                    </CupoySrefView>
                    <Nav>
                        <NavList>
                            <NavItem isActive={CupoyRouter.isActive(CupoyRouter.STATES.HOME)}>
                                <CupoySrefView
                                    statename={CupoyRouter.STATES.HOME}
                                >
                                    <NavItemTouch>首頁</NavItemTouch>
                                </CupoySrefView>
                            </NavItem>
                            <NavItem isActive={CupoyRouter.includes(CupoyRouter.STATES.GAME)}>
                                <CupoySrefView
                                    statename={CupoyRouter.STATES.GAME}
                                >
                                    <NavItemTouch>虛寶市集</NavItemTouch>
                                </CupoySrefView>
                            </NavItem>
                            <NavItem
                                isActive={
                                    CupoyRouter.includes(CupoyRouter.STATES.POINTCARD) ||
                                    CupoyRouter.isActive(CupoyRouter.STATES.POINTCARD_PRODUCT)
                                }
                            >
                                <CupoySrefView
                                    statename={CupoyRouter.STATES.POINTCARD}
                                >
                                    <NavItemTouch>點數卡</NavItemTouch>
                                </CupoySrefView>
                            </NavItem>
                            <NavItem
                                isActive={
                                    CupoyRouter.includes(CupoyRouter.STATES.VITEMPRODUCTS) ||
                                    CupoyRouter.isActive(CupoyRouter.STATES.VITEM_PRODUCT)
                                }
                            >
                                <CupoySrefView
                                    statename={CupoyRouter.STATES.VITEMPRODUCTS}
                                >
                                    <NavItemTouch>虛寶包</NavItemTouch>
                                </CupoySrefView>
                            </NavItem>
                            <NavItem
                                isActive={
                                    CupoyRouter.includes(CupoyRouter.STATES.FREEPRODUCTS) ||
                                    CupoyRouter.isActive(CupoyRouter.STATES.FREE_PRODUCT)
                                }
                            >
                                <CupoySrefView
                                    statename={CupoyRouter.STATES.FREEPRODUCTS}
                                >
                                    <NavItemTouch>免費索取</NavItemTouch>
                                </CupoySrefView>
                            </NavItem>
                            <NavItem
                                isActive={
                                    CupoyRouter.includes(CupoyRouter.STATES.BONUSPRODUCTS) ||
                                    CupoyRouter.isActive(CupoyRouter.STATES.BONUS_PRODUCT)
                                }
                            >
                                <CupoySrefView
                                    statename={CupoyRouter.STATES.BONUSPRODUCTS}
                                >
                                    <NavItemTouch>發財商城</NavItemTouch>
                                </CupoySrefView>
                            </NavItem>
                            <NavItem isActive={CupoyRouter.includes(CupoyRouter.STATES.PROFITEVENT)}>
                                <CupoySrefView
                                    statename={CupoyRouter.STATES.PROFITEVENT}
                                >
                                    <NavItemTouch>好康活動</NavItemTouch>
                                </CupoySrefView>
                            </NavItem>
                            <NavItem
                                isRed
                                isActive={CupoyRouter.isActive(CupoyRouter.STATES.NEWS_CONTENT, { id: '1590395703976' })}
                            >
                                <CupoySrefView
                                    statename={CupoyRouter.STATES.NEWS_CONTENT}
                                    params={{ id: '1590395703976' }}
                                >
                                    <NavItemTouch>慎防詐騙</NavItemTouch>
                                </CupoySrefView>
                            </NavItem>
                        </NavList>
                    </Nav>
                </Content>
            </GlobalStyle.Topbar>
        );
    }

    renderMember = () => {

        const { memberinfo } = this.props;

        const {
            membername = '',
            nickname = '',
            shownametype,
        } = memberinfo;

        const memerAvatarRsc = GLOBAL.getMemberAvatarRsc(memberinfo);

        return (
            <MemberWrapper>
                <Dropdown
                    ref={ref => this.profileDropdownRef = ref}
                    defaultVisible={false}
                    overlay={this.renderProfileMenu()}
                    trigger={['click']}
                    placement="bottomCenter"
                    overlayClassName="dropdown popup open arrow-left arrow-top"
                    overlayStyle={{ 
                        position: 'fixed',
                        width: '183px',
                        zIndex: 1001,
                    }}
                >
                    <MemberWrapper>
                        <CupoyImage 
                            src={memerAvatarRsc}
                            stylecomponent={Avatar}
                            isBackgroundImage
                        />
                        {
                            shownametype === 1 ?
                            membername.trim()
                            :
                            nickname.trim()
                        }
                        <DropdownIcon>
                            <i className="zmdi zmdi-caret-down"></i>
                        </DropdownIcon>
                    </MemberWrapper>
                </Dropdown>
            </MemberWrapper>
        );
    }

    renderProfileMenu = () => {

        return (
            <MemberDropdown>
                {
                    this.PROFILE_NAVS.map(({
                        name,
                        iconName,
                        iconSize,
                        stateName,
                        action,
                    }) => {
                        return (
                            stateName ?
                            <CupoySrefView
                                statename={stateName}
                                key={name}
                            >
                                <MemberDropdownListItem onClick={this.hideProfileDropdown}>
                                    <MemberDropdownIcon size={iconSize}>
                                        <i className={iconName}></i>
                                    </MemberDropdownIcon>
                                    {name}
                                </MemberDropdownListItem>
                            </CupoySrefView>
                            :
                            <MemberDropdownListItem key={name} onClick={action}>
                                <MemberDropdownIcon size={iconSize}>
                                    <i className={iconName}></i>
                                </MemberDropdownIcon>
                                {name}
                            </MemberDropdownListItem>
                        );
                    })
                }
            </MemberDropdown>
        );
    }

    renderSubTopbar = () => {

        const {
            haslogin,
            cartItems = [],
            memberinfo,
        } = this.props;

        const {
            membermsgcnt = 0,
            csmsgcnt = 0,
        } = memberinfo;

        return (
            <SubTopbar>
                {haslogin
                    ? (
                        <SubTopbarContainer>
                            {this.renderMember()}
                            <SubTopbarNavItemWrapper>
                                <CupoySrefView statename={CupoyRouter.STATES.MEMBER_CONTACT_SERVICE_ADD_COMPLAINT}>
                                    <SubTopbarNavItem>
                                        會員客訴
                                        {csmsgcnt > 0 && (
                                            <SubTopbarNavItemCount>
                                                {csmsgcnt}
                                            </SubTopbarNavItemCount>
                                        )}
                                    </SubTopbarNavItem>
                                </CupoySrefView>
                            </SubTopbarNavItemWrapper>
                            <SubTopbarNavItemWrapper>
                                <CupoySrefView statename={CupoyRouter.STATES.HELP_UPGRADE_MEMBERSHIP}>
                                    <SubTopbarNavItem>
                                        幫助中心
                                    </SubTopbarNavItem>
                                </CupoySrefView>
                            </SubTopbarNavItemWrapper>
                            <SubTopbarNavItemWrapper>
                                <CupoySrefView statename={CupoyRouter.STATES.MEMBER_SYSTEM_MESSAGE}>
                                    <SubTopbarNavItem>
                                        系統訊息
                                        {membermsgcnt > 0 && (
                                            <SubTopbarNavItemCount>
                                                {membermsgcnt}
                                            </SubTopbarNavItemCount>
                                        )}
                                    </SubTopbarNavItem>
                                </CupoySrefView>
                            </SubTopbarNavItemWrapper>
                            <SubTopbarNavItemWrapper>
                                <CupoySrefView statename={CupoyRouter.STATES.MEMBER_CARD_ORDER_LIST_FINISH}>
                                    <SubTopbarNavItem>
                                        我的訂單
                                        {false && (
                                            <SubTopbarNavItemCount>
                                                2
                                            </SubTopbarNavItemCount>
                                        )}
                                    </SubTopbarNavItem>
                                </CupoySrefView>
                            </SubTopbarNavItemWrapper>
                            <SubTopbarNavItemWrapper>
                                <CupoySrefView statename={CupoyRouter.STATES.MEMBER_CART}>
                                    <SubTopbarNavItem>
                                        <ShoppingCartIcon>
                                            <i className="zmdi zmdi-shopping-cart"></i>
                                        </ShoppingCartIcon>
                                        購物車
                                        {cartItems.length > 0 && (
                                            <SubTopbarNavItemCount>
                                                {cartItems.length}
                                            </SubTopbarNavItemCount>
                                        )}
                                    </SubTopbarNavItem>
                                </CupoySrefView>
                            </SubTopbarNavItemWrapper>
                        </SubTopbarContainer>
                    ) : (
                        <SubTopbarContainer>
                            <MemberWrapper onClick={this.showSignInDialog}>
                                會員登入
                            </MemberWrapper>
                            <SubTopbarNavItemWrapper>
                                <CupoySrefView
                                    statename={CupoyRouter.STATES.REGISTER}
                                >
                                    <SubTopbarNavItem>
                                        會員註冊
                                    </SubTopbarNavItem>
                                </CupoySrefView>
                            </SubTopbarNavItemWrapper>
                            <SubTopbarNavItemWrapper>
                                <CupoySrefView
                                    statename={CupoyRouter.STATES.HELP_UPGRADE_MEMBERSHIP}
                                >
                                    <SubTopbarNavItem>
                                        幫助中心
                                    </SubTopbarNavItem>
                                </CupoySrefView>
                            </SubTopbarNavItemWrapper>
                        </SubTopbarContainer>
                    )
                }
            </SubTopbar>
        );
    }

}

/*--------------------------
    export
--------------------------*/

/*--------------------------
    Reducer Props
--------------------------*/
const mapStateToProps = function(state) {
    return {
        history: state.web.history,
        haslogin: state.member.haslogin,
        memberinfo: state.member.memberinfo,
        cartItems: state.cardcart.cartItems,
    };
};

/*--------------------------
    Reducer Action
--------------------------*/
const mapActionToProps = function(dispatch) {
    return {
        webActions: bindActionCreators(actionCreators.webActionCreators, dispatch),
        memberActions: bindActionCreators(actionCreators.memberActionCreators, dispatch),
        cardCartActions: bindActionCreators(actionCreators.cardCartActionCreators, dispatch),
    };
};

/*--------------------------
    export
--------------------------*/
export default connect(mapStateToProps, mapActionToProps, null, {
    forwardRef: true
})(TopbarView);