
import webActions from './webActions';
import customAxios from 'api/customAxios';
import actionTypes from '../types/cardOrderActionTypes';

const API_URL = '/CardOrderAction.do';

function listCardOrdersStarted(payload) {
    return { type: actionTypes.listCardOrdersStarted, payload };
}
function listCardOrdersSuccess(payload) {
    return { type: actionTypes.listCardOrdersSuccess, payload };
}
function listCardOrdersFailed(payload) {
    return { type: actionTypes.listCardOrdersFailed, payload };
}
function listCardOrders(payload = {}) {

    const {
        start,
        cartid,
        refresh = false,
    } = payload;

    return dispatch => {
        dispatch(listCardOrdersStarted(payload)); // dispatch synced action to update loading status

        const promise = new Promise((resolve, reject) => {


            let form = new FormData();
            form.append('op', 'listCardOrders');
            form.append('start', start);

            if(cartid) {
                form.append('cartid', cartid);
            }

            customAxios(API_URL,
                    {
                        method: "POST",
                        body: form
                    }
            )
                .then(response => {
                    
                    if (response.status === 503) {
                        // server正常，apache也存活，但是resin已經被關閉的情況下會出現此錯誤
                        reject({
                            error: 'error.503error',
                            message: '伺服器沒有開啟'
                        });

                        return;
                    }
                    response
                        .json()
                        .then(result => {
                            if (!result) {
                                reject();
                                return;
                            }

                            if(result.error) {
                                reject(result.error);
                                return 
                            }

                            dispatch(listCardOrdersSuccess({
                                cartid,
                                result,
                                refresh,
                            }));
                            resolve(result);
                        })
                        .catch(error => {
                            dispatch(listCardOrdersFailed(error));
                            reject(error);
                        });
                })
                .catch(error => {
                    // 手機沒有開網路、錯誤的連線網址或是server完全無法連線（包含AWS以及apache都沒有開的狀態)才會出現此錯誤
                    dispatch(listCardOrdersFailed(error));
                    reject(error);
                });
        });
        return promise;
    };
}

function qyCardOrderDetailStarted(payload) {
    return { type: actionTypes.qyCardOrderDetailStarted, payload };
}
function qyCardOrderDetailSuccess(payload) {
    return { type: actionTypes.qyCardOrderDetailSuccess, payload };
}
function qyCardOrderDetailFailed(payload) {
    return { type: actionTypes.qyCardOrderDetailFailed, payload };
}
function qyCardOrderDetail(payload = {}) {
    return dispatch => {

        dispatch(qyCardOrderDetailStarted(payload)); // dispatch synced action to update loading status

        const promise = new Promise((resolve, reject) => {

            
            let form = new FormData();
            form.append('op', 'qyCardOrderDetail');
            if(payload.orderid){
                form.append('orderid', payload.orderid);
            } else {
                form.append('cartid', payload.cartid);
            }
            
            form.append('start', payload.start);

            customAxios(API_URL,
                    {
                        method: "POST",
                        body: form
                    }
            )
                .then(response => {
                    
                    if (response.status === 503) {
                        // server正常，apache也存活，但是resin已經被關閉的情況下會出現此錯誤
                        reject({
                            error: 'error.503error',
                            message: '伺服器沒有開啟'
                        });

                        return;
                    }
                    response
                        .json()
                        .then(result => {
                            if (!result) {
                                reject();
                                return;
                            }

                            if(result.error) {
                                reject(result.error);
                                return 
                            }

                            dispatch(qyCardOrderDetailSuccess({result, ...payload}));
                            resolve(result);
                        })
                        .catch(error => {
                            dispatch(qyCardOrderDetailFailed(error));
                            reject(error);
                        });
                })
                .catch(error => {
                    // 手機沒有開網路、錯誤的連線網址或是server完全無法連線（包含AWS以及apache都沒有開的狀態)才會出現此錯誤
                    dispatch(qyCardOrderDetailFailed(error));
                    reject(error);
                });
        });
        return promise;
    };
}

function setCardUsedFlagStarted(payload) {
    return { type: actionTypes.setCardUsedFlagStarted, payload };
}
function setCardUsedFlagSuccess(payload) {
    return { type: actionTypes.setCardUsedFlagSuccess, payload };
}
function setCardUsedFlagFailed(payload) {
    return { type: actionTypes.setCardUsedFlagFailed, payload };
}
function setCardUsedFlag(payload = {}) {
    return dispatch => {

        dispatch(setCardUsedFlagStarted(payload)); // dispatch synced action to update loading status

        const promise = new Promise((resolve, reject) => {

            
            let form = new FormData();
            form.append('op', 'setCardUsedFlag');
            form.append('jcardid', payload.jcardid);
            form.append('used', payload.used);

            customAxios(API_URL,
                    {
                        method: "POST",
                        body: form
                    }
            )
                .then(response => {
                    
                    if (response.status === 503) {
                        // server正常，apache也存活，但是resin已經被關閉的情況下會出現此錯誤
                        reject({
                            error: 'error.503error',
                            message: '伺服器沒有開啟'
                        });

                        return;
                    }
                    response
                        .json()
                        .then(result => {
                            if (!result) {
                                reject();
                                return;
                            }

                            if(result.error) {
                                reject(result.error);
                                return 
                            }

                            dispatch(setCardUsedFlagSuccess({result, ...payload}));
                            resolve(result);
                        })
                        .catch(error => {
                            dispatch(setCardUsedFlagFailed(error));
                            reject(error);
                        });
                })
                .catch(error => {
                    // 手機沒有開網路、錯誤的連線網址或是server完全無法連線（包含AWS以及apache都沒有開的狀態)才會出現此錯誤
                    dispatch(setCardUsedFlagFailed(error));
                    reject(error);
                });
        });
        return promise;
    };
}

function listBonusOrdersStarted() {
    return { type: actionTypes.listBonusOrdersStarted };
}
function listBonusOrdersSuccess(result, refresh) {
    return {
        type: actionTypes.listBonusOrdersSuccess,
        payload: {
            result,
            refresh,
        },
    };
}
function listBonusOrdersFailed(payload) {
    return { type: actionTypes.listBonusOrdersFailed, payload };
}
function listBonusOrders(start, options = {}) {
    return dispatch => {
        const promise = new Promise((resolve, reject) => {

            dispatch(listBonusOrdersStarted()); // dispatch synced action to update loading status

            const { refresh = false } = options;

            const url = `${API_URL}?op=listBonusOrders&start=${refresh ? 0 : start}`;

            customAxios(url)
                .then(response => {
                    
                    if (response.status === 503) {
                        // server正常，apache也存活，但是resin已經被關閉的情況下會出現此錯誤
                        reject({
                            error: 'error.503error',
                            message: '伺服器沒有開啟'
                        });

                        return;
                    }
                    response
                        .json()
                        .then(result => {
                            if (!result) {
                                reject();
                                return;
                            }

                            if(result.error) {
                                reject(result.error);
                                return;
                            }

                            dispatch(listBonusOrdersSuccess(result, refresh));
                            resolve(result);
                        })
                        .catch(error => {
                            dispatch(listBonusOrdersFailed(error));
                            reject(error);
                        });
                })
                .catch(error => {
                    // 手機沒有開網路、錯誤的連線網址或是server完全無法連線（包含AWS以及apache都沒有開的狀態)才會出現此錯誤
                    dispatch(listBonusOrdersFailed(error));
                    reject(error);
                });
        });
        return promise;
    };
}

function buyFreeCardProductStarted(payload) {
    return { type: actionTypes.buyFreeCardProductStarted, payload };
}
function buyFreeCardProductSuccess(payload) {
    return { type: actionTypes.buyFreeCardProductSuccess, payload };
}
function buyFreeCardProductFailed(payload) {
    return { type: actionTypes.buyFreeCardProductFailed, payload };
}
function buyFreeCardProduct(payload = {}) {
    return (dispatch, getState) => {

        const {
            haslogin,
            memberinfo = {},
        } = getState().member;

        const {
            idvalidflag,
            isemailauth,
            issmsauth,
        } = memberinfo;

        if (!haslogin) {
            dispatch(webActions.needLogin());
            return;
        }

        if (!idvalidflag) {
            dispatch(webActions.openAlertDialog({
                title: '發生錯誤',
                description: '身分證未驗證',
            }));
            return;
        }

        if (!isemailauth) {
            dispatch(webActions.openAlertDialog({
                title: '發生錯誤',
                description: '電子信箱未驗證',
            }));
            return;
        }

        if (!issmsauth) {
            dispatch(webActions.openAlertDialog({
                title: '發生錯誤',
                description: '行動電話未驗證',
            }));
            return;
        }

        dispatch(buyFreeCardProductStarted(payload)); // dispatch synced action to update loading status

        const promise = new Promise((resolve, reject) => {

            
            let form = new FormData();
            form.append('op', 'buyFreeCardProduct');
            form.append('productid', payload.productid);
            form.append('count', payload.count);
            form.append('jsdproductid', payload.jsdproductid);
            payload.idnumber && form.append('idnumber', payload.idnumber);

            customAxios(API_URL,
                    {
                        method: "POST",
                        body: form
                    }
            )
                .then(response => {
                    
                    if (response.status === 503) {
                        // server正常，apache也存活，但是resin已經被關閉的情況下會出現此錯誤
                        reject({
                            error: 'error.503error',
                            message: '伺服器沒有開啟'
                        });

                        return;
                    }
                    response
                        .json()
                        .then(result => {
                            if (!result) {
                                reject();
                                return;
                            }

                            if(result.error) {
                                
                                let errorMessage = result.error.message;

                                if (result.error.code) {

                                    switch (result.error.code) {
                                        case 'error.nonnAccountNotValidatedError':
                                            errorMessage = '索取權限驗證失敗';
                                            break;
                                        case 'error.NonnAccountNotAllowError':
                                            errorMessage = '會員停權中';
                                            break;
                                        default:
                                            break;
                                    }
                                }
                                reject({
                                    code: result.error.code,
                                    message: errorMessage,
                                });
                                return;
                            }

                            dispatch(buyFreeCardProductSuccess(result));
                            resolve(result);
                        })
                        .catch(error => {
                            dispatch(buyFreeCardProductFailed(error));
                            reject(error);
                        });
                })
                .catch(error => {
                    // 手機沒有開網路、錯誤的連線網址或是server完全無法連線（包含AWS以及apache都沒有開的狀態)才會出現此錯誤
                    dispatch(buyFreeCardProductFailed(error));
                    reject(error);
                });
        });
        return promise;
    };
}



const actions = {
    listCardOrders, // 列出點卡訂單
    qyCardOrderDetail, // 取得點卡訂單詳細資料
    setCardUsedFlag, // 設定點卡已使用flag
    listBonusOrders, // 列出發財商城訂單
    buyFreeCardProduct, // 購買免費索取商品
    
};

export default actions;